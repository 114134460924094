<template>
  <div ref="dom" class="charts chart-bar"></div>
</template>

<script>
import * as echarts from "echarts";
import { on, off } from "@/libs/tools";
export default {
  name: "ChartBar",
  props: {
    value: Object,
    loading: String,
    subtext: String,
  },
  data() {
    return {
      dom: null,
    };
  },
  watch: {
    value: {
      handler(val) {
        // val.push({
        //   value: 0,
        //   itemStyle: {
        //     color: "#fff",
        //     opacity: 0.5,
        //   },
        // });
        // console.log(val);
        this.loadEcharts(val);
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {},
  methods: {
    resize() {
      this.dom.resize();
    },
    loadEcharts(val) {
      this.$nextTick(() => {
        let data1 = [],data2 = []
        if (val.data[0].value >= 0 && val.data[0].value <= 90){
          data1 = val.data
        } else if (val.data[0].value > 90 && val.data[0].value <= 180){
          data2 = [{value:-(90-(val.data[0].value - 90))}]
        } else if (val.data[0].value > 180 && val.data[0].value <= 270){
          data2 = [{value:(val.data[0].value - 180)}]
        } else {
          data1 = [{value:-(90 - (val.data[0].value - 270))}]
        }
        let option = {
          calculable: true,
          polar: [
            {
              id: 1,
              z: 1,
              center: ["50%", "50%"],
              radius: "90%",
            },
            {
              id: 2,
              z: 2,
              center: ["50%", "50%"],
              radius: "90%",
            },
          ],
          radiusAxis: [
            {
              name: "桥",
              polarIndex: 0,
              nameTextStyle: {
                fontSize: 12,
                color: "#fff",
                padding: [6, 15, 6, 15],
                backgroundColor: "rgba(0,209,254,.15)",
              },
              // 极坐标半径刻度
              axisLine: {
                show: false,
                lineStyle: {
                  width: 5,
                  type: "solid",
                  color: "red",
                  opacity: 0.8,
                },
              },
              axisTick: {
                show: true,
              },
              axisLabel: {
                show: false,
              },
              splitLine: {
                show: true,
              },
            },
            {
              name: "",
              polarIndex: 1,
              nameTextStyle: {
                fontSize: 12,
                color: "#fff",
                padding: [6, 15, 6, 15],
                backgroundColor: "rgba(0,209,254,.15)",
              },
              // 极坐标半径刻度
              axisLine: {
                show: false,
                lineStyle: {
                  width: 5,
                  type: "solid",
                  color: "red",
                  opacity: 0.8,
                },
              },
              axisTick: {
                show: true,
              },
              axisLabel: {
                show: false,
              },
              splitLine: {
                show: true,
              },
            },
          ],
          angleAxis: [
            { //桥的线
              polarIndex: 0,
              silent: false,
              type: "category",
              // min: 0,
              // max: 360,
              interval: 45,
              startAngle: val.bridgeAngle + 90,
              clockwise: false,
              z: 5,
              splitLine: {
                show: true,
                interval: "auto",
                lineStyle: {
                  width: 2,
                  type: "solid",
                  color: "#fff",
                },
              },
              axisLine: {
                show: false,
              },
              axisTick: {
                show: false,
              },
              axisLabel: {
                show: false,
              },
              data: [270, 90],
            },
            {
              polarIndex: 1,
              type: "category",
              // min: 0,
              // max: 360,
              // interval: 45,
              startAngle: 90,
              clockwise: false,
              z: 5,
              splitLine: {
                show: true,
                interval: "auto",
                lineStyle: {
                  width: 1,
                  type: "dotted",
                  color: "#fff",
                },
              },
              axisLine: {
                show: false,
              },
              axisTick: {
                show: false,
              },
              axisLabel: {
                show: false,
              },
              data: [0, 180],
            },
          ],
          series: [
            {
              name: "",
              type: "gauge",
              center: ["50%", "50%"],
              radius: "70%",
              clockwise: false,
              startAngle: 0,
              endAngle: 180,
              splitNumber: 4,
              min: -90,
              max: 90,
              axisLine: {
                show: true,
                lineStyle: {
                  color: [
                    [0.25, "#f2c000"],
                    [0.5, "#00d4ff"],
                    [0.75, "#00d4ff"],
                    [1, "#f2c000"],
                    // [0.625, "#f2c000"],
                    // [0.75, "#00d4ff"],
                    // [0.875, "#00d4ff"],
                    // [1, "#f2c000"],
                  ],
                  width: 15,
                },
              },
              axisTick: {
                show: false,
              },
              splitLine: {
                show: true,
                length: 15,
                distance: -15,
                lineStyle: {
                  width: 2,
                  color: "#fff",
                },
              },
              axisLabel: {
                show: true,
                distance: 25,
                color: "#fff",
                fontSize: "12",
                // fontWeight: "bold",
                formatter: function (value) {
                  // console.log(value)
                  if (value == -90){
                    return `{a|270}`;
                  } else if (value == 90 || value == 0) {
                    return `{a|${value}}`;
                  }
                },

                rich: {
                  a: {
                    color: "#EBC029",
                    padding: [0, 0, 10, 0],
                  },
                  b: {
                    color: "#00D1FE",
                  },
                  x: {
                    fontSize: 0,
                    color: "transparent",
                  },
                },
              },
              pointer: {
                show: true,
                width: 5,
                length: "70%",
                itemStyle: {
                  color: "#fff",
                  opacity: 1,
                },
              },
              detail: {
                show: false,
              },
              data: data1,
            },
            {
              name: "",
              type: "gauge",
              center: ["50%", "50%"],
              radius: "70%",
              clockwise: false,
              startAngle: -180,
              endAngle: 0,
              splitNumber: 4,
              min: -90,
              max: 90,
              axisLine: {
                show: true,
                lineStyle: {
                  color: [
                    [0.25, "#f2c000"],
                    [0.5, "#00d4ff"],
                    [0.75, "#00d4ff"],
                    [1, "#f2c000"],
                  ],
                  width: 15,
                },
              },
              axisTick: {
                show: false,
              },
              splitLine: {
                show: true,
                length: 15,
                distance: -15,
                lineStyle: {
                  width: 2,
                  color: "#fff",
                },
              },
              axisLabel: {
                show: true,
                distance: 25,
                color: "#fff",
                fontSize: "12",
                formatter: function (value) {
                  // console.log(value);
                  if (value === 0) {
                    return `{b|180}`;
                  }
                },
                rich: {
                  a: {
                    color: "#EBC029",
                  },
                  b: {
                    color: "#00D1FE",
                  },
                },
              },
              pointer: {
                show: true,
                width: 5,
                length: "70%",
                itemStyle: {
                  color: "#fff",
                  opacity: 1,
                },
              },
              detail: {
                show: false,
              },
              data: data2,
            },
          ],
        };
        if (this.dom != null && this.dom != "" && this.dom != undefined) {
          this.dom.dispose();
        }
        this.dom = echarts.init(this.$refs.dom);
        this.dom.setOption(option);
        on(window, "resize", this.resize);
        this.$parent.changeLoad(this.loading)
      });
    },
  },

  beforeDestroy() {
    off(window, "resize", this.resize);
  },
};
</script>
