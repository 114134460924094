import * as THREE from 'three';
import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";
import {GLTFLoader} from "three/examples/jsm/loaders/GLTFLoader";
import {DRACOLoader} from "three/examples/jsm/loaders/DRACOLoader"

export class TestEngine {

    constructor(dom = new HTMLElement(),window,vue) {
        this.dom = dom;
        this.renderer = new THREE.WebGLRenderer({antialias: true,alpha: true});
        this.scene = new THREE.Scene();
        this.camera = new THREE.PerspectiveCamera(45, (this.dom.offsetWidth-20) / (this.dom.offsetHeight-20), 1, 1000000);
        this.pointLight = new THREE.PointLight( 0xffffff, 2.5 );
        this.pointLight.position.set(0,0,50000);
        this.rayCaster = new THREE.Raycaster();

        // 渲染器配置
        this.renderer.setSize(this.dom.offsetWidth-20, this.dom.offsetHeight-20, true);

        // 场景配置
        // this.scene.add(new THREE.AmbientLight(0xffffff, 1)); // 加入环境光,模型各面的光照强度无差异
        // this.scene.add(this.pointLight); // 加入点光源
        this.sun = new THREE.DirectionalLight( 0xffffff, 1 );
        // this.sun.position.set(50000,50000,50000);
        this.scene.add(this.sun); // 加入平行光
        let sun1 = new THREE.DirectionalLight( 0xffffff, 2 );
        sun1.position.set(50000,50000,50000);
        this.scene.add(sun1); // 加入平行光
        let sun2 = new THREE.DirectionalLight( 0xffffff, 2 );
        sun2.position.set(-50000,50000,-50000);
        this.scene.add(sun2); // 加入平行光

        // 聚光灯光源
        // const spotLight = new THREE.SpotLight(0xffffff, 1);
        // spotLight.position.set(200,200,200);
        // this.scene.add(spotLight);

        // this.scene.add(new THREE.AxesHelper(500)); // 加入坐标系
        // this.scene.add(new THREE.GridHelper(1050, 21, 0xFFFFFF, 0xFFFFFF)); // 加入网格辅助

        // 相机配置
        this.camera.position.set(600, 100, 600);
        this.camera.lookAt(0, 0, 0);

        // 控制器配置
        const controls = new OrbitControls(this.camera, this.renderer.domElement);

        let self = this;
        const dracoLoader = new DRACOLoader();
        dracoLoader.setDecoderPath("/model/draco/")
        dracoLoader.preload();
        const gltfLoader = new GLTFLoader();
        gltfLoader.setDRACOLoader(dracoLoader);
        gltfLoader.load('/model/bridge.glb', function (gltf) {
            const model = gltf.scene;
            model.rotateY(Math.PI);
            model.position.set(963,0,-1267);
            self.scene.add(model);
            // 添加点位信息  depth桥面宽，width点宽度，height点高度
            const pointList = [
                {
                    name: "pointA",
                    position: [-348,0,332]
                },
                {
                    name: "pointB",
                    position: [-312,0,308]
                },
                {
                    name: "pointC",
                    position: [-302,0,298]
                },
                {
                    name: "pointD",
                    position: [-252,0,248]
                },
                {
                    name: "pointE",
                    position: [-242,0,238]
                },
                {
                    name: "pointF",
                    position: [-222,0,218]
                },
                {
                    name: "pointG",
                    position: [-162,0,158]
                },
                {
                    name: "pointH",
                    position: [-82,0,78]
                },
                {
                    name: "pointI",
                    position: [62,0,-58]
                },
                {
                    name: "pointJ",
                    position: [0,0,0]
                },
                {
                    name: "pointK",
                    position: [82,0,-78]
                },
                {
                    name: "pointL",
                    position: [162,0,-158]
                },
                {
                    name: "pointN",
                    position: [282,0,-278]
                },
                {
                    name: "pointO",
                    position: [312,0,-308]
                },
                {
                    name: "pointP",
                    position: [-242,220,238]
                },
                {
                    name: "pointQ",
                    position: [250,220,-240]
                },
                {
                    name: "pointR",
                    position: [-242,-40,238]
                },
                {
                    name: "pointS",
                    position: [250,-40,-240]
                },
            ];
            for (let i = 0; i < pointList.length; i++) {
                let item = pointList[i];
                let pointModel=new THREE.Mesh(new THREE.BoxGeometry( 4, 4, 50),new THREE.MeshBasicMaterial( {color: 0x00ff00}));
                pointModel.name=item.name;
                pointModel.position.set(...item.position);
                pointModel.rotateY(Math.PI/4);
                self.scene.add(pointModel);
            }
        })



        // 添加至dom
        this.dom.appendChild(this.renderer.domElement);
        const animation = () => {
            // console.log(self.camera.position)
            controls.update();
            let vector = this.camera.position.clone();
            let scale = 1;
            // this.pointLight.position.set(scale * vector.x, scale * vector.y,scale * vector.z); //点光源位置
            this.sun.position.set(scale * vector.x, scale * vector.y,scale * vector.z);
            this.renderer.render(this.scene, this.camera);
            requestAnimationFrame(animation);
        };
        animation();

        // 添加事件
        window.addEventListener("mouseup", (event) => {
            let rayCaster = this.rayCaster;
            event.preventDefault(); //阻止默认事件
            let container = this.dom;
            let pointer = new THREE.Vector2(
                ((event.clientX - container.offsetLeft - 10) / (container.clientWidth - 20)) * 2 - 1,
                -((event.clientY - container.offsetTop - 10) / (container.clientHeight - 20)) * 2 + 1);
            //获取和射线相交的物体
            rayCaster.setFromCamera(pointer, this.camera);
            let intersects = rayCaster.intersectObjects(this.scene.children, true);
            if (intersects.length > 0) {
                console.log('intersects',intersects)
                if (intersects[0].object.name.includes('point')) {
                    let mesh = intersects[0].object;
                    console.log('yes!', mesh, mesh.name);
                    let pointName = mesh.name.replace("point","");
                    vue.getTreeData(pointName);
                }
            } else {
                console.log("未选中 Mesh!");
            }
        }, false);
        window.addEventListener("resize", () => {
            this.camera.aspect = (this.dom.offsetWidth-20) / (this.dom.offsetHeight-20);
            this.camera.updateProjectionMatrix();
            this.renderer.setSize(this.dom.offsetWidth-20, this.dom.offsetHeight-20, true);
        });
    }
}
