<template>
  <div ref="dom" class="charts chart-bar"></div>
</template>

<script>
import * as echarts from "echarts";
import { on, off } from "@/libs/tools";
export default {
  name: "ChartBar",
  props: {
    value: Object,
    text: String,
    subtext: String,
  },
  data() {
    return {
      dom: null,
    };
  },
  watch: {
    value: {
      handler(val) {
        // console.log(val);
        this.loadEcharts(val);
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {},
  methods: {
    resize() {
      this.dom.resize();
    },
    loadEcharts(val) {
      this.$nextTick(() => {
        let option = {
          tooltip: {
            trigger: "axis",
          },
          legend: {
            show: false,
          },
          grid: {
            top: "25%",
            left: "15%",
            right: "10%",
            bottom: "15%",
            // containLabel: true
          },
          xAxis: [
            {
              type: "category",
              boundaryGap: true,
              axisLine: {
                //坐标轴轴线相关设置。数学上的x轴
                show: false,
                lineStyle: {
                  color: "#233653",
                },
              },
              axisLabel: {
                //坐标轴刻度标签的相关设置
                color: "#A4C9F97F",
                opacity: "0.5",
                padding: 4,
                fontSize: 12,
                formatter: function (data) {
                  return data;
                },
              },
              splitLine: {
                show: false,
                lineStyle: {
                  type: "dashed",
                  color: "#4CC6FF",
                },
              },
              axisTick: {
                show: false,
              },
              data: val.xaxis,
            },
          ],
          yAxis: [
            {
              name: "",
              nameTextStyle: {
                color: "#3CB6FC",
                fontSize: 12,
                padding: 10,
                align: "right",
              },
              // min: 0,
              scale:true,
              splitLine: {
                show: true,
                lineStyle: {
                  type: "dashed",
                  color: "rgba(76,198,255,0.25)",
                },
              },
              axisLine: {
                show: false,
                lineStyle: {
                  color: "#233653",
                },
              },
              axisLabel: {
                show: true,

                color: "#DEF8FF7F",
                padding: 10,

                formatter: function (value) {
                  if (value === 0) {
                    return value;
                  }
                  return value;
                },
              },
              axisTick: {
                show: false,
              },
            },
          ],
          series: [
            {
              name: "",
              type: "line",
              symbol: "circle", // 默认是空心圆（中间是白色的），改成实心圆
              showAllSymbol: true,
              symbolSize: 0,
              smooth: false,
              lineStyle: {
                width: 2,
                color: "#FED33D", // 线条颜色

                borderColor: "rgba(0,0,0,.4)",
              },
              itemStyle: {
                color: "rgba(235,192,41,1)",
                borderColor: "#646ace",
                borderWidth: 0,
              },
              tooltip: {
                show: true,
              },
              areaStyle: {
                //区域填充样式

                //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                color: new echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: "rgba(235,192,41,.4)",
                    },
                    {
                      offset: 1,
                      color: "rgba(235,192,41, 0)",
                    },
                  ],
                  false
                ),
                shadowColor: "rgba(235,192,41, 0.5)", //阴影颜色
                shadowBlur: 20, //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
              },
              data: val.yaxis,
            },
          ],
        };
        if (this.dom != null && this.dom != "" && this.dom != undefined) {
          this.dom.dispose();
        }
        this.dom = echarts.init(this.$refs.dom);
        this.dom.setOption(option);
        on(window, "resize", this.resize);
        this.$parent.changeLoad('loading5')
      });
    },
  },

  beforeDestroy() {
    off(window, "resize", this.resize);
  },
};
</script>
