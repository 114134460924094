<template>
  <div ref="dom" class="charts chart-bar"></div>
</template>

<script>
import * as echarts from "echarts";
import { on, off } from "@/libs/tools";
export default {
  name: "ChartBar",
  props: {
    value: Object,
    text: String,
    subtext: String,
    loading: String,
  },
  data() {
    return {
      dom: null,
      option:{
        xAxis:[{data:[]}],
        series: [{ data: [] }, { data: [] }]
      }
    };
  },
  watch: {
    value: {
      handler(val) {
        this.loadEcharts(val);
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.loadEcharts(this.value)
  },
  methods: {
    resize() {
      this.dom.resize();
    },
    loadEcharts(val) {
      let self = this
      this.$nextTick(() => {
        self.option = {
          tooltip: {
            trigger: "axis",
          },
          legend: {
            show: true,
            top: "20%",
            right: "5%",
            itemWidth: 12,
            itemHeight: 3,
            textStyle: {
              color: "#fff",
              fontSize: 12,
            },
          },
          grid: {
            top: "30%",
            left: "15%",
            right: "10%",
            bottom: "15%",
            // containLabel: true
          },
          xAxis: [
            {
              type: "category",
              boundaryGap: true,
              axisLine: {
                //坐标轴轴线相关设置。数学上的x轴
                show: false,
                lineStyle: {
                  color: "#233653",
                },
              },
              axisLabel: {
                //坐标轴刻度标签的相关设置

                color: "#93DCFE",
                opacity: "0.5",
                padding: 4,
                fontSize: 12,

                formatter: function (data) {
                  return data;
                },
              },
              splitLine: {
                show: false,
                lineStyle: {
                  type: "dashed",
                  color: "rgba(76,198,255,0.25)",
                },
              },
              axisTick: {
                show: false,
              },
              data: val.xAxis,
            },
          ],
          yAxis: [
            {
              name: "KN",
              nameTextStyle: {
                color: "#3CB6FC",
                fontSize: 12,
                padding: 10,
                align: "right",
              },
              splitLine: {
                show: true,
                lineStyle: {
                  type: "dashed",
                  color: "rgba(76,198,255,0.25)",
                },
              },
              axisLine: {
                show: false,
                lineStyle: {
                  color: "#233653",
                },
              },
              axisLabel: {
                show: true,

                color: "#93DCFE",
                padding: 10,

                formatter: function (value) {
                  if (value === 0) {
                    return value;
                  }
                  return value;
                },
              },
              axisTick: {
                show: false,
              },
            },
          ],
          series: [
            {
              name: "监测值",
              type: "bar",
              itemStyle: {
                borderRadius: 0,
                color: "#00ceff",
              },
              barWidth: "20%",
              label: {
                show: false,
                color: "#74A2FF",
                position: "outside",
              },
              data: val.series[0].data,
            },
            // 坡地
            {
              name: "成桥值",
              type: "bar",
              itemStyle: {
                borderRadius: 0,
                color: "#f2c000",
              },
              barWidth: "20%",
              label: {
                show: false,
                color: "#42C3B9",
                position: "outside",
              },
              data: val.series[1].data,
            },
          ],
        };
        if (!self.dom) {
          this.dom = echarts.init(this.$refs.dom);
        }
        this.dom.setOption(self.option);
        on(window, "resize", this.resize);
        this.$parent.changeLoad(this.loading);
      });
    },
    changeData(val){
      let self = this
      self.$nextTick(() => {
        self.option.xAxis[0].data = val.xAxis
        self.option.series[0].data = val.series[0].data
        self.option.series[1].data = val.series[1].data
      })
    }
  },

  beforeDestroy() {
    off(window, "resize", this.resize);
  },
};
</script>
