<template>
  <div ref="dom" class="charts chart-bar"></div>
</template>

<script>
import * as echarts from "echarts";
import { on, off } from "@/libs/tools";
export default {
  name: "ChartBar",
  props: {
    value: Array,
    text: String,
    subtext: String,
    loading: String,
  },
  data() {
    return {
      dom: null,
    };
  },
  watch: {
    value: {
      handler(val) {
        this.loadEcharts(val);
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {},
  methods: {
    resize() {
      this.dom.resize();
    },
    loadEcharts(val) {
      this.$nextTick(() => {
        var colors = [
          "#af214e",
          "#1c346e",
          "#4491bb",
          "#c66f81",
          "#78bdbf",
          "#59909c",
        ];
        let option = {
          // backgroundColor: '#fff',
          color: colors,
          calculable: true,
          polar: {
            center: ["50%", "50%"],
            radius: "65%",
          },
          tooltip: {
            show: true,
          },
          radiusAxis: {
            // max: 100,
            min: 0,
            axisTick: {
              show: false,
            },
            axisLine: {
              show: false,
              lineStyle: {
                type: "dashed",
                color: "#6d8a92",
              },
            },
            axisLabel: {
              show: false,
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: "dashed",
                color: "#6d8a92",
              },
            },
            splitArea: {
              areaStyle: {
                color: "transparent",
              },
            },
          },
          angleAxis: {
            type: "category",
            // data: txtArr,
            // min: 0,
            // max: 360,
            // interval: 90,
            startAngle: 102,
            z: 10,
            splitLine: {
              show: true,
              interval: "auto",
              lineStyle: {
                width: 1,
                type: "solid",
                color: "#dee0ea",
              },
            },
            axisLine: {
              show: false,
            },
            axisTick: {
              show: true,
              length: 12,
              alignWithLabel: true,
              lineStyle: {
                width: 1,
                type: "solid",
                color: "#dee0ea",
              },
            },
            axisLabel: {
              // align:'left',
              show: true,
              fontSize: 12,
              padding: 8,
              color: "#fff",
            },
            data: [
              "北",
              "",
              "",
              "",
              "东",
              "",
              "",
              "",
              "南",
              "",
              "",
              "",
              "西",
              "",
              "",
              "",
            ],
          },

          series: [
            {
              type: "bar",
              data: val[0].value,
              coordinateSystem: "polar",
              name: "",
              stack: "a",
              itemStyle: {
                borderWidth: 1,
                borderColor: "#ffffff",
              },
            },
          ],
        };
        if (!this.dom) {
          this.dom = echarts.init(this.$refs.dom);
        }
        this.dom = echarts.init(this.$refs.dom);
        this.dom.setOption(option);
        on(window, "resize", this.resize);
        this.$parent.changeLoad(this.loading);
      });
    },
  },

  beforeDestroy() {
    off(window, "resize", this.resize);
  },
};
</script>
