<template>
  <div ref="dom" class="charts chart-bar"></div>
</template>

<script>
import * as echarts from "echarts";
import { on, off } from "@/libs/tools";
export default {
  name: "ChartBar",
  props: {
    value: Object,
    text: String,
    loading: String,
  },
  data() {
    return {
      dom: null,
    };
  },
  watch: {
    value: {
      handler(val) {
        if(val.xAxis.length) {
          this.loadEcharts(val);
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {},
  methods: {
    resize() {
      this.dom.resize();
    },
    loadEcharts(val) {
      this.$nextTick(() => {
        let option = {
          //   backgroundColor: "#0e1c47",
          tooltip: {
            trigger: "axis",
            // backgroundColor: "transparent",
          },
          legend: {
            show: true,
            top: "5%",
            right: '5%',
            textStyle: {
              color: "#E5E5E5",
              opacity: 0.88,
              fontSize: 12,
            },
          },
          grid: {
            top: "25%",
            left: "8%",
            right: "5%",
            bottom: "15%",
            // containLabel: true
          },
          xAxis: [
            {
              type: "category",
              // boundaryGap: true,
              boundaryGap: ["5%", "5%"],
              axisLine: {
                //坐标轴轴线相关设置。数学上的x轴
                show: false,
                lineStyle: {
                  color: "#233653",
                },
              },
              axisLabel: {
                //坐标轴刻度标签的相关设置
                color: "#93DCFE",
                // opacity: "0.5",
                padding: 4,
                fontSize: 12,

                formatter: function (data) {
                  return data;
                },
              },
              splitLine: {
                show: false,
                lineStyle: {
                  type: "dashed",
                  color: "#4CC6FF",
                },
              },
              axisTick: {
                show: true,
              },
              data: val.xAxis,
            },
          ],
          yAxis: [
            {
              name: "平均车速 (km/h)",
              nameTextStyle: {
                color: "#E5E5E5",
                opacity: 0.88,
                fontSize: 12,
                padding: 0,
                align: "center",
              },
              min: 0,
              splitLine: {
                show: true,
                lineStyle: {
                  type: "dashed",
                  color: "rgba(76,198,255,0.25)",
                },
              },
              axisLine: {
                show: false,
                lineStyle: {
                  color: "#233653",
                },
              },
              axisLabel: {
                show: true,
                // textStyle: {

                color: "#93DCFE",
                padding: 10,
                // opacity:'0.5',
                // },
                formatter: function (value) {
                  if (value === 0) {
                    return value;
                  }
                  return value;
                },
              },
              axisTick: {
                show: false,
              },
            },
          ],
          series: [
            {
              // name: "当日上行平均车速",
              name: "当日左幅平均车速",
              type: "line",
              symbol: "circle", // 默认是空心圆（中间是白色的），改成实心圆
              showAllSymbol: true,
              symbolSize: 0,
              smooth: true,
              lineStyle: {
                // normal: {
                width: 2,
                color: "#05FFF6", // 线条颜色
                // },
                borderColor: "rgba(0,0,0,.4)",
              },
              itemStyle: {
                color: "rgba(25,163,223,1)",
                borderColor: "#646ace",
                borderWidth: 0,
              },
              tooltip: {
                show: true,
              },
              areaStyle: {
                //区域填充样式
                // normal: {
                //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                color: new echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: "rgba(5, 255, 246, 0.4)",
                    },
                    {
                      offset: 1,
                      color: "rgba(5, 255, 246, 0)",
                    },
                  ],
                  false
                ),
                shadowColor: "rgba(25,163,223, 0.5)", //阴影颜色
                shadowBlur: 20, //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
                // },
              },
              data: val.series[0].data,
            },
            {
              // name: "当日下行平均车速",
              name: "当日右幅平均车速",
              type: "line",
              symbol: "circle", // 默认是空心圆（中间是白色的），改成实心圆
              showAllSymbol: true,
              symbolSize: 0,
              smooth: true,
              lineStyle: {
                width: 2,
                color: "#9999FF", // 线条颜色

                borderColor: "rgba(0,0,0,.4)",
              },
              itemStyle: {
                color: "rgba(25,163,223,1)",
                borderColor: "#646ace",
                borderWidth: 0,
              },
              tooltip: {
                show: true,
              },
              areaStyle: {
                //区域填充样式
                //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                color: new echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: "rgba(153, 153, 255, 0.4)",
                    },
                    {
                      offset: 1,
                      color: "rgba(153, 153, 255, 0)",
                    },
                  ],
                  false
                ),
                shadowColor: "rgba(153, 153, 255, 0.5)", //阴影颜色
                shadowBlur: 20, //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
              },
              data: val.series[1].data,
            },
          ],
        };
        // if (this.dom != null && this.dom != "" && this.dom != undefined) {
        //   this.dom.dispose();
        // }
        if (!this.dom) {
          this.dom = echarts.init(this.$refs.dom);
        }
        this.dom = echarts.init(this.$refs.dom);
        this.dom.setOption(option);
        on(window, "resize", this.resize);
        this.$parent.changeLoad(this.loading);
      });
    },
  },

  beforeDestroy() {
    off(window, "resize", this.resize);
  },
};
</script>
