<template>
  <div ref="dom" class="charts chart-bar"></div>
</template>

<script>
import * as echarts from "echarts";
import { on, off } from "@/libs/tools";
export default {
  name: "ChartBar",
  props: {
    value: Object,
    loading: String,
    subtext: String,
  },
  data() {
    return {
      dom: null,
    };
  },
  watch: {
    value: {
      handler(val) {
        // val.push({
        //   value: 0,
        //   itemStyle: {
        //     color: "#fff",
        //     opacity: 0.5,
        //   },
        // });
        // console.log(val);
        this.loadEcharts(val);
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {},
  methods: {
    resize() {
      this.dom.resize();
    },
    loadEcharts(val) {
      this.$nextTick(() => {
        let option = {
          calculable: true,
          polar: {
            center: ["45%", "50%"],
            radius: "100%",
          },
          radiusAxis: {
            name: " 梁横截面",
            nameTextStyle: {
              fontSize: 14,
              color: "#fff",
              padding: [8, 6, 8, 6],
              backgroundColor: "rgba(0,209,254,.15)",
            },
            // 极坐标半径刻度
            axisLine: {
              show: true,
              lineStyle: {
                width: 1,
                type: "dotted",
                color: "#fff",
                opacity: 0.8,
              },
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: false,
            },
            splitLine: {
              show: false,
            },
          },
          angleAxis: {
            // min: 0,
            // max: 0,
            interval: 360,
            startAngle: 0,
            z: 5,
            splitLine: {
              show: false,
              interval: "auto",
            },
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: false,
            },
          },
          series: [
            {
              name: "",
              type: "gauge",
              center: ["45%", "50%"],
              radius: "80%",
              clockwise: false,
              startAngle: -90,
              endAngle: 90,
              splitNumber: 6,
              min: 90,
              max: -90,
              axisLine: {
                show: true,
                lineStyle: {
                  color: [
                    [0.17, "#00d4ff"],
                    [0.33, "#00d4ff"],
                    [0.5, "#f2c000"],
                    [0.67, "#f2c000"],
                    [0.83, "#00d4ff"],
                    [1, "#00d4ff"],
                  ],
                  width: 15,
                },
              },
              axisTick: {
                show: false,
              },
              splitLine: {
                show: true,
                length: 15,
                distance: -15,
                lineStyle: {
                  width: 2,
                  color: "#fff",
                },
              },
              axisLabel: {
                show: true,
                distance: 25,
                color: "#fff",
                fontSize: "12",
                // fontWeight: "bold",
              },
              pointer: {
                show: true,
                width: 5,
                length: "64%",
                itemStyle: {
                  color: "#fff",
                  opacity: 0.9,
                },
              },
              // itemStyle:{
              //   color: "red",
              //     opacity: 0.9,
              // },
              detail: {
                show: false,
              },
              data: val.data,
            },
            {
              name: "",
              type: "gauge",
              center: ["45%", "50%"],
              radius: "80%",
              clockwise: false,
              startAngle: -90,
              endAngle: 90,
              splitNumber: 6,
              min: 90,
              max: -90,
              axisLine: {
                show: true,
                lineStyle: {
                  color: [
                    [0.17, "#00d4ff"],
                    [0.33, "#00d4ff"],
                    [0.5, "#f2c000"],
                    [0.67, "#f2c000"],
                    [0.83, "#00d4ff"],
                    [1, "#00d4ff"],
                  ],
                  width: 15,
                },
              },
              axisTick: {
                show: false,
              },
              splitLine: {
                show: true,
                length: 15,
                distance: -15,
                lineStyle: {
                  width: 2,
                  color: "#fff",
                },
              },
              axisLabel: {
                show: true,
                distance: 25,
                color: "#fff",
                fontSize: "12",
                // fontWeight: "bold",
              },
              pointer: {
                show: true,
                width: 9,
                // icon:'roundRect',
                length: "80%",
                itemStyle: {
                  color: "#fff",
                  opacity: 0.4,
                  borderWidth:5,
                  borderCap:'round',
                  borderMiterLimit:20,
                },
              },
              detail: {
                show: false,
              },
              data: [{value:0}],
            },
          ],
        };
        if (this.dom != null && this.dom != "" && this.dom != undefined) {
          this.dom.dispose();
        }
        this.dom = echarts.init(this.$refs.dom);
        this.dom.setOption(option);
        on(window, "resize", this.resize);
        this.$parent.changeLoad(this.loading)
      });
    },
  },

  beforeDestroy() {
    off(window, "resize", this.resize);
  },
};
</script>
