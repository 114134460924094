<template>
  <div class="single-home-page h-full">
    <s-header @menu-click="menuClick" :title="headTitle"></s-header>
    <component :is="current"></component>
  </div>
</template>

<script>
import SHeader from "@/components/SHeader";
import ScreenOne from "./components/screen01";
import ScreenTwo from "./components/screen02";
import ScreenThree from "./components/screen03";
export default {
  components: {
    SHeader,
    ScreenOne,
    ScreenTwo,
    ScreenThree,
  },
  data() {
    return {
      current: "ScreenOne",
      headTitle: "桥梁健康监测系统",
      baseUrl:''
    };
  },
  watch:{
    '$route.query':{
      handler(val){
        let current = val.current || 1;
        this.getCurrentCom(current);
      },
      immediate:true,
      deep:true,
    }
  },
  created() {
    let params = this.getAllUrlParams();
    if (params.bridge) {
      localStorage.setItem("baseUrl", params.bridge)
    }
    let bridgePrefix = localStorage.getItem("baseUrl");
    let bridgeNameList = this.$BRIDGE_NAME_LIST;
    if (bridgePrefix) {
      for (let i = 0; i < bridgeNameList.length; i++) {
        if (bridgeNameList[i].baseUrl === bridgePrefix) {
          this.headTitle = bridgeNameList[i].name + "健康监测系统";
        }
      }
    }
    this.baseUrl = localStorage.getItem('baseUrl')
  },
  methods: {
    menuClick(current) {
      this.$router.push({ query: { current } });
    },
    getCurrentCom(current) {
      switch (Number(current)) {
        case 1:
          this.current = "ScreenOne";
          break;
        case 2:
          this.current = "ScreenTwo";
          break;
        case 3:
          this.current = "ScreenThree";
          break;
        case 4:
          this.current = "ScreenThree";
          break;
      }
    },
    getAllUrlParams() {
      // 获取完整的URL
      let fullUrl = window.location.href;

      // 使用正则表达式提取查询参数部分
      let match = fullUrl.match(/\?(.*)/);

      // 检查是否匹配到查询参数
      if (match) {
        let queryString = match[1];
        let params = queryString.split("&");
        let queryParams = {};

        for (var i = 0; i < params.length; i++) {
          let param = params[i].split("=");
          let key = decodeURIComponent(param[0]);
          let value = decodeURIComponent(param[1] || "");

          queryParams[key] = value;
        }

        return queryParams;
      } else {
        console.log('当前URL没有查询参数。');
        return {};
      }
    },
  },
};
</script>
