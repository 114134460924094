<template>
  <div class="s-header flex justify-between relative">
    <div class="header-title flex items-center">
      <span class="mr-2">{{ title }}</span>
<!--      <i class="iconfont icon-jiantou_zuoyouqiehuan" style="font-size: 24px" @click.stop="goto" v-if="notIframe"></i>-->
      <el-dropdown @command="handleCommand" v-if="notIframe">
        <i class="el-icon-arrow-down el-icon--right" style="color: white"></i>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item :command="value" v-for="(value, key) in bridgeNameList" :key="key">{{ value.name }}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <div class="header-l flex items-center">
      <div
        class="menu-item"
        :class="{ active: current == 1 }"
        @click="handleMenuClick(1)"
      >
        总览驾驶舱
      </div>
    </div>
    <div class="header-r flex items-center">
      <div style="width: 120px;margin-right: 15px; cursor: pointer;" @click="goto" v-if="notIframe">
        <img style="width: 100%;margin-top: 2px" src="../../assets/images/single/guanlipingtai.png" alt="">
      </div>
      <div
          class="menu-item"
          :class="{ active: current == 2 }"
          @click="handleMenuClick(2)"
      >
        交通驾驶舱
      </div>
<!--      <div-->
<!--        class="menu-item"-->
<!--        :class="{ active: current == 3 }"-->
<!--        @click="handleMenuClick(3)"-->
<!--      >-->
<!--        涡振监测-->
<!--      </div>-->
<!--      <div-->
<!--        class="menu-item"-->
<!--        :class="{ active: current == 4 }"-->
<!--        @click="handleMenuClick(4)"-->
<!--      >-->
<!--        地震监测-->
<!--      </div>-->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "湖北省公路特大和特殊结构桥梁健康监测系统",
    },
  },
  data() {
    return {
      current: 1,
      bridgeNameList:{},
      bridgePrefix: "",
      notIframe: self === top,
    };
  },
  created() {
    this.current = this.$route.query.current || 1;
    this.bridgeNameList = this.$BRIDGE_NAME_LIST;
    this.bridgePrefix = localStorage.getItem("baseUrl");
  },
  methods: {
    handleMenuClick(current) {
      this.current = current;
      this.$emit("menu-click", current);
    },
    goto() {
      for(let item of this.bridgeNameList){
        if (this.bridgePrefix === item.baseUrl){
          this.$http.get('/bridge/sys/menu/tree/bri',{params:{id:item.id}}).then(res => {
            if (res.success){
              localStorage.setItem('menuList',JSON.stringify(res.data));
              this.$router.push(this.$DEFAULT_URL);
            } else {
              this.$message.error(res.msg)
            }
            return
          })
        }
      }
    },
    // 下拉菜单事件
    handleCommand(command) {
      if(command.baseUrl === this.bridgePrefix) return ;
      let self = this;
      this.$http.get('/bridge/sys/menu/tree/bri',{params:{id:command.id}}).then(res => {
        if (res.success){
          localStorage.setItem('menuList',JSON.stringify(res.data));
          localStorage.setItem("baseUrl", command.baseUrl);
          this.$parent.baseUrl = command.baseUrl
          self.__proto__.__proto__.$MQURL = "ws://"+window.location.host+"/mqsocket/" + command.baseUrl + "/";
          this.$message({
            message: '切换中，请稍后...',
            duration: 1000,
            type: 'info',
            onClose: function () {
              self.reload();
            }
          });
        } else {
          this.$message.error(res.msg)
        }
      })
    },
  },
  inject: ['reload']
};
</script>

<style lang="less" scoped>
.s-header {
  height: 74px;
  background: url("../../assets/images/single/header_bg.png") top center
    no-repeat;
  background-size: 100% 100%;
  .header-title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 26px;
    color: #fff;
    .icon-qiehuan {
      font-size: 28px;
      cursor: pointer;
    }
  }
  .header-l {
    padding-left: 8%;
  }
  .header-r {
    padding-right: 8%;
  }
  .menu-item {
    width: 120px;
    height: 40px;
    margin: 2px 10px 0;
    font-size: 16px;
    text-align: center;
    line-height: 40px;
    color: #fff;
    background: url("../../assets/images/single/btn_press.png") no-repeat;
    background-size: 100% 100%;
    opacity: 0.5;
    cursor: pointer;
    transition: all ease 0.38s;
    &:hover,
    &.active {
      opacity: 1;
    }
  }
}
</style>
