<template>
  <div class="screen-pannel screen-one screen-jiashicang flex flex-col justify-between">
    <div class="s-top flex justify-between">
      <div class="s-left flex flex-col justify-between">
        <div class="s-item s-left-1"
             v-loading="loading1"
             element-loading-text="拼命加载中"
             element-loading-spinner="el-icon-loading"
             element-loading-background="rgba(5, 31, 86, 1)"
        >
          <div class="s-new-title">
            <span>结构健康度监测</span>
          </div>
          <div class="sj-content">
            <div class="jgjk-item">
              <div class="jgjk-item-top">构件健康度</div>
              <div class="jgjk-item-bottom" :class="componentColor">
                <span>{{ componentHealth }}</span>
              </div>
            </div>
            <div class="jgjk-item">
              <div class="jgjk-item-top">整体健康度</div>
              <div class="jgjk-item-bottom" :class="wholeColor">
                <span>{{ wholeHealth }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="s-item"
             v-loading="loading2"
             element-loading-text="拼命加载中"
             element-loading-spinner="el-icon-loading"
             element-loading-background="rgba(5, 31, 86, 1)"
        >
          <div class="s-new-title">车流量监测</div>
<!--          <div class="s-new-title">数据监测</div>-->
          <div class="sj-content flex items-center justify-center">
            <chart-three
                style="height: 100%; width: 50%"
                loading="loading2"
                :value="chartData2"
            ></chart-three>
            <div class="traffic-flow-new div-scroll">
              <div class="traffic-item">
                <div class="t-label ellipsis" title="日总数">日总数</div>
<!--                <div class="t-label ellipsis" title="今日数据量">今日数据量</div>-->
                <div class="t-value ellipsis" :title="chartData2.dayTotal">
                  {{ chartData2.dayTotal }}
                </div>
              </div>
              <div class="traffic-item">
                <div class="t-label ellipsis" title="日超重数">日超重数</div>
<!--                <div class="t-label ellipsis" title="本月数据量">本月数据量</div>-->
                <div
                    class="t-value ellipsis"
                    :title="chartData2.dayUpLimitTotal"
                >
                  {{ chartData2.dayUpLimitTotal }}
                </div>
              </div>
              <div class="traffic-item">
                <div class="t-label ellipsis" title="月总数">月总数</div>
<!--                <div class="t-label ellipsis" title="季度数据量">季度数据量</div>-->
                <div class="t-value ellipsis" :title="chartData2.monthTotal">
                  {{ chartData2.monthTotal }}
                </div>
              </div>
              <div class="traffic-item">
                <div class="t-label ellipsis" title="月超重数">月超重数</div>
<!--                <div class="t-label ellipsis" title="年度数据量">年度数据量</div>-->
                <div class="t-value ellipsis" :title="chartData2.monthUpLimitTotal">
                  {{ chartData2.monthUpLimitTotal }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="s-center" id="pano1" ref="pano1">
        <img
            src="../../../../assets/images/single/jiaotong/icon_fullscreen.png"
            class="icon-fullscreen"
            alt=""
            v-if="!isFullscreen"
            style="z-index: 9999"
            @click.stop="handleFullscreen"
        />
        <div :class="isFullscreen ? 's-c-dialog s-c-dialog-full' : 's-c-dialog'" v-if="dialogShow">
          <i class="el-icon-close" @click="closeDialog"></i>
          <div class="s-d-title">{{ pointType }}截面</div>
          <div class="s-d-left">
            <el-tree
                ref="tree"
                :data="treeData"
                node-key="id"
                :highlight-current="true"
                :current-node-key="currentKey"
                :default-expanded-keys="defaultExpand"
                @node-click="checkChange"
                accordion
                :props="defaultProps">
            </el-tree>
          </div>
          <div class="s-d-right" id="bridge1"></div>
        </div>
        <div :class="isFullscreen ? 's-c-time s-c-time-full' : 's-c-time'" v-if="timeChartShow">
          <i class="el-icon-close" @click="closeTimeLine"></i>
          <chart-time :vtour-name="pointName"></chart-time>
        </div>
      </div>
      <div class="s-right flex flex-col justify-between">
        <div class="s-item">
          <div class="s-new-title">
            <span>结构报警监测</span>
          </div>
          <div class="chart-container alert-container">
            <div class="alert-total flex items-center">
              <div class="total-item flex justify-center items-center" style="cursor: pointer" @click="changeWarn('warn')">
                <div class="total-icon total-icon-1"></div>
                <div class="total-info">
                  <p class="t-info-label ellipsis" title="报警总数">报警总数</p>
                  <p class="t-info-value blue ellipsis">{{warnTotal}}</p>
                </div>
              </div>
              <div class="total-item flex justify-center items-center" style="cursor: pointer"  @click="changeWarn('special')">
                <div class="total-icon total-icon-2"></div>
                <div class="total-info">
                  <p class="t-info-label ellipsis" title="特殊事件">特殊事件</p>
                  <p class="t-info-value orange ellipsis">{{specialEvevtTotal}}</p>
                </div>
              </div>
            </div>
            <vue-seamless-scroll
                v-if="alertList.length !== 0"
                :data="alertList"
                class="seamless-warp"
                :class-option="classOption"
            >
              <div class="alert-list">
                <el-row
                    class="a-list-item"
                    v-for="(item, index) in alertList"
                    :key="index"
                >
                  <el-col :span="6">{{ item.name }}</el-col>
                  <el-col :span="4">{{ item.value }}</el-col>
                  <el-col :span="5">{{ item.level }}</el-col>
                  <el-col :span="9">{{ item.date }}</el-col>
<!--                  <el-col :span="4">{{ item.time }}</el-col>-->
                </el-row>
              </div>
            </vue-seamless-scroll>
            <div class="list-empty" v-else>暂无数据</div>
          </div>
        </div>
        <div class="s-item">
          <div class="s-new-title">
            <span>设备在线监测</span>
          </div>
          <div
              class="sj-content"
              v-loading="loading3"
              element-loading-text="拼命加载中"
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(5, 31, 86, 1)"
          >
            <ul class="zxl-list">
              <li>
                <div class="num">{{ situationData.onlineRate || 0 }}%</div>
                <div class="name">传感器在线率</div>
              </li>
              <li>
                <div class="num">{{ situationData.onlineSize || 0 }}</div>
                <div class="name">在线数量</div>
              </li>
              <li>
                <div class="num">{{ situationData.offlineSize || 0 }}</div>
                <div class="name">离线数量</div>
              </li>
            </ul>
            <!--            <chart-seven-->
            <!--                style="height: calc(100% - 50px)"-->
            <!--                :value="chartData3"-->
            <!--            ></chart-seven>-->
          </div>
        </div>
      </div>
    </div>
    <div class="s-bottom w-full flex justify-between">
      <div class="s-bottom-item">
        <div class="s-new-title">
          <span>运营环境监测</span>
        </div>
        <div
            class="sj-content flex"
            v-loading="loading4"
            element-loading-text="拼命加载中"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(5, 31, 86, 1)"
        >
          <chart-six
              style="height: 100%; width: 50%"
              loading="loading4"
              :value="chartData4"
          ></chart-six>
          <ul class="env-list h-full">
            <li class="env-list-item flex items-center">
              <img
                  src="../../../../assets/images/single/screen01/icon_fengsu.png"
                  alt=""
                  class="env-icon"
              />
              <div class="env-info">
                <p class="env-label">风速</p>
                <p class="env-value color1" v-if="monitor.windS || monitor.windS === 0">
                  {{ parseFloat(monitor.windS).toFixed(2) }}m/s
                </p>
                <p class="env-value color1" v-else>--m/s</p>
              </div>
            </li>
            <li class="env-list-item flex items-center">
              <img
                  src="../../../../assets/images/single/screen01/icon_fengxiang.png"
                  alt=""
                  class="env-icon"
              />
              <div class="env-info">
                <p class="env-label">风向</p>
                <p class="env-value color2" v-if="monitor.windD || monitor.windD === 0">
                  {{ parseFloat(monitor.windD).toFixed(2) }}°
                </p>
                <p class="env-value color2" v-else>--°</p>
              </div>
            </li>
            <li class="env-list-item flex items-center">
              <img
                  src="../../../../assets/images/single/screen01/icon_wendu.png"
                  alt=""
                  class="env-icon"
              />
              <div class="env-info">
                <p class="env-label">温度</p>
                <p class="env-value color3" v-if="monitor.temperature || monitor.temperature === 0">
                  {{ parseFloat(monitor.temperature).toFixed(2) }}℃
                </p>
                <p class="env-value color3" v-else>--℃</p>
              </div>
            </li>
            <li class="env-list-item flex items-center">
              <img
                  src="../../../../assets/images/single/screen01/icon_shidu.png"
                  alt=""
                  class="env-icon"
              />
              <div class="env-info">
                <p class="env-label">湿度</p>
                <p class="env-value color4" v-if="monitor.humidity">
                  {{ parseFloat(monitor.humidity).toFixed(2) }}%
                </p>
                <p class="env-value color4" v-else>--%</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="s-bottom-large flex items-center justify-between">
        <div class="s-bottom-item" v-if="show && cableForceShow == 1">
          <div class="s-new-title">
            <span>全桥索力</span>
          </div>
          <div class="sj-content relative"
               v-loading="loading5"
               element-loading-text="拼命加载中"
               element-loading-spinner="el-icon-loading"
               element-loading-background="rgba(5, 31, 86, 1)"
          >
            <div class="s-title-right flex items-center">
              <div class="title-right-item active">
                <el-checkbox v-model="cableCheck" :true-label="0" :false-label="1" @change="cableChecked">相对值
                </el-checkbox>
              </div>
              <div class="title-right-item" :class="{ active: isSlUp }" @click="changeCable(true)">左幅</div>
              <div class="title-right-item" :class="{ active: !isSlUp }" @click="changeCable(false)">右幅</div>
            </div>
            <chart-four
                style="height: 100%"
                :value="chartData5"
                loading="loading5"
            ></chart-four>
          </div>
        </div>
        <div v-if="show" class="s-bottom-item" :class="{ 's-bottom-full': show && cableForceShow != 1 }">
          <div class="s-new-title">
            <span>{{ lineCheck === 1 ? "全桥线形" : "全桥挠曲线" }}</span>
          </div>
          <div
              class="sj-content relative"
              v-loading="loading6"
              element-loading-text="拼命加载中"
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(5, 31, 86, 1)"
          >
            <div class="s-title-right">
              <div class="title-right-item active">
                <el-checkbox v-model="lineCheck" :true-label="0" :false-label="1" @change="lineChecked">相对值
                </el-checkbox>
              </div>
              <div class="title-right-item" :class="{ active: isXxUp }" @click="changeLine(true)">左幅</div>
              <div class="title-right-item" :class="{ active: !isXxUp }" @click="changeLine(false)">右幅</div>
            </div>
            <chart-five
                v-if="!loading6"
                style="width: 100%; height: 100%"
                :value="chartData6"
                loading="loading6"
                ref="chartFive"
            ></chart-five>
          </div>
        </div>
      </div>
      <div class="s-bottom-item">
        <div class="s-new-title">
          <span>结构响应监测</span>
        </div>
        <div
            class="sj-content sj-rb-content"
            v-loading="loading7"
            element-loading-text="拼命加载中"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(5, 31, 86, 1)"
        >
          <div class="structure-pannel h-1/2 flex justify-center items-center" v-if="responseList.length && !loading7">
            <div class="structure-item"
                 v-for="(item, index) in responseList.slice(0,Math.floor(responseList.length / 2))" :key="index">
              <div class="str-value">{{ item.value ? item.value+item.unit : "--" }}</div>
              <div class="str-label" :title="item.name">{{ item.name }}</div>
            </div>
          </div>
          <div class="structure-pannel h-1/2 flex justify-center" v-if="responseList.length && !loading7">
            <div class="structure-item" v-for="(item, index) in responseList.slice(Math.floor(responseList.length / 2))"
                 :key="index">
              <div class="str-value">{{ item.value ? item.value+item.unit : "--" }}</div>
              <div class="str-label" :title="item.name">{{ item.name }}</div>
            </div>
          </div>
          <div class="list-empty" v-else>暂无数据</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import vueSeamlessScroll from "vue-seamless-scroll";
  import ChartThree from "./charts/chart03.vue";
  import ChartFour from "./charts/chart04.vue";
  import ChartFive from "./charts/chart05.vue";
  import ChartSix from "./charts/chart06.vue";
  import ChartSeven from "./charts/chart07.vue";
  import {TestEngine} from '@/assets/js/TestEngine'
  import ChartTime from "./vtour/timeChart.vue";

  export default {
    components: {
      vueSeamlessScroll,
      ChartThree,
      ChartFour,
      ChartFive,
      ChartSix,
      ChartTime,
      ChartSeven,
    },
    data() {
      return {
        loading1: true,
        loading2: true,
        loading3: true,
        loading4: true,
        loading5: true,
        loading6: true,
        loading7: true,
        isSx: true,
        isSlUp: true,
        isXxUp: true,
        cableCheck: 1,
        lineCheck: 1,
        chartData2: {
          carTotal: 0,
          dayTotal: 0,
          dayUpLimitTotal: 0,
          monthTotal: 0,
          monthUpLimitTotal: 0,
          pieData: [],
        },
        chartData3: {
          data: [1281, 2390, 2483, 2317, 2481, 2450, 1228],
          xaxis: ["07-21", "07-22", "07-23", "07-24", "07-25", "07-26", "07-27"],
        },
        chartData4: [
          {
            name: "",
            value: [17, 32, 36, 50],
          },
        ],
        chartData5: {
          xAxis: [],
          series: [{data: []}, {data: []}],
        },
        chartData6: {
          xAxis: [],
          series: [{data: []}, {data: []}],
          unit: "",
        },
        monitor: {
          humidity: 0,
          temperature: 0,
          windD: 0,
          windS: 0,
        },
        type:'warn',
        warnTotal:0,
        specialEvevtTotal:0,
        alertList: [],
        krpano: null,
        xmlName: "/vtour/tour-" + localStorage.getItem("baseUrl") + ".xml",
        timer: null,
        show: false,
        cableForceShow: "",
        situationData: {
          onlineSize: "",
          offlineSize: "",
          onlineRate: "",
        },
        // 结构健康度
        componentHealth: "基本完好",
        componentColor: "green",
        wholeHealth: "基本完好",
        wholeColor: "green",
        // 结构响应列表
        responseList: [],
        chartTimer: null,
        isSk: false,
        treeData: [],
        defaultProps: {
          children: 'children',
          label: 'name',
        },
        defaultExpand: [],
        currentKey: '',
        svg: {
          zoom_: null,
          svg_: null,
          svg1: null,
          container: null,
          container1: null,
          svg_width: 500,
          svg_height: 180,
          isScale: 0
        },
        loading: false,
        imgWidth: 500,
        imgHeight: 200,
        colorList: [
          '#5470c6',
          '#91cc75',
          '#fac858',
          '#ee6666',
          '#73c0de',
          '#3ba272',
          '#fc8452',
          '#9a60b4',
          '#ea7ccc'],
        chartTimeData: [],
        timeLoading: false,
        timeChartShow: false,//控制时程曲线的显隐
        dialogShow: false, //控制桥弹窗的显隐
        pointName: '',
        pointType: '',
        currentId: '',
        isFullscreen: false,
      };
    },
    computed: {
      classOption() {
        return {
          step: 0.1, // 数值越大速度滚动越快
          limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
          hoverStop: true, // 是否开启鼠标悬停stop
          direction: 1, // 0向下 1向上 2向左 3向右
          openWatch: true, // 开启数据实时监控刷新dom
          singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
          singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
          waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
        };
      },
    },
    created() {
      console.log(this.$parent.baseUrl);
      if (this.$parent.baseUrl === 'zjh') {
        this.isXxUp = false;
        this.changeLine(false);
      }
      this.getData3();
      this.getData4();
      this.getData7();
      this.getSituationData();
      this.getMonitor();
      this.isCable();
      this.getResult();
      this.getReponseList();
      this.getWarnData()
      let self = this;
      self.chartTimer = setInterval(function () {
        self.getData3();
        self.getData4();
        self.getData7();
        self.getSituationData();
        self.getMonitor();
        self.getData5();
        self.getResult();
        self.getReponseList();
        self.getWarnData()
      }, 60000);
    },
    mounted() {
      let self = this;
      this.isDjksk();
      // this.isSk = true
      if (this.isSk) {
        // 监听键盘Esc事件 还原事件弹窗信息
        window.addEventListener('resize', function () {
          self.isFullscreen = !self.isFullscreen
          self.initSvg(self.currentId, self.pointType)
        });
        new TestEngine(this.$refs.pano1, window, this);
      } else {
        window.addEventListener('resize', function () {
          self.isFullscreen = !self.isFullscreen
        });
        embedpano({
          xml: self.xmlName,
          target: "pano1",
          html5: "auto",
          mobilescale: 1.0,
          passQueryParameters: true,
          onready: function (krpano_interface) {
            self.krpano = krpano_interface;
          }, //回调函数，获取Krpano 对象
        });
        localStorage.removeItem("hotspot");
        this.timer = setInterval(() => {
          let obj = null;
          let oldObj = null;
          if (localStorage.getItem("hotspot")) {
            obj = localStorage.getItem("hotspot");
            oldObj = localStorage.getItem("oldHotspot");
            if (obj !== oldObj){
              this.getTreeData(obj)
            }
          }
        }, 300);
      }
      // this.initSvg(212)
    },
    methods: {
      changeWarn(type){
        this.type = type
        this.getWarnData()
      },
      closeTimeLine() {
        this.timeChartShow = false
      },
      closeDialog() {
        this.timeChartShow = false
        this.dialogShow = false
        localStorage.removeItem("hotspot")
        localStorage.removeItem("oldHotspot")
      },
      getTreeData(type) {
        localStorage.removeItem("hotspot")
        localStorage.removeItem("oldHotspot")
        this.dialogShow = true;
        this.pointType = localStorage.getItem("hotspotName");
        this.$http.get('/sensor/special/structure/sectionTree', {params: {type: type}}).then(res => {
          if (res.success) {
            this.treeData = res.data
            console.log('treeData', this.treeData)
            if (this.treeData.length > 0) {
              this.initSvg(this.treeData[0].id, type)
              this.$nextTick(() => {
                this.currentId = this.treeData[0].id
                this.defaultExpand = [this.treeData[0].id]
                this.$refs["tree"].setCurrentKey(this.treeData[0].id)
              })
            }
          }
        })
      },
      checkChange(data) {
        if (!data.children) {
          this.pointName = data.name
          this.timeChartShow = true
          // this.initSvg(data.id)
        } else {
          this.timeChartShow = false
          this.currentId = data.id
          this.initSvg(data.id, this.pointType)
        }
      },
      shrink(isOpen) {
        this.isOpen = !isOpen
      },
      isCable() {
        this.$http.get("/total/overview/has/cableForce").then((res) => {
          if (res.success) {
            this.cableForceShow = res.data;
            this.show = true;
            this.getData5();
            // if(this.cableForceShow != 1) {
            //   this.$refs.chartFive.resize();
            // }
          }
        });
      },
      changePass(type) {
        this.isSx = type;
        this.getData2(this.isSx);
      },
      changeCable(type) {
        this.isSlUp = type;
        this.getData4();
      },
      cableChecked() {
        this.getData4();
      },
      getWarnData(){
        this.$http.get('/total/overview/structuralWarnMonitor').then(res => {
          if (res.success){
            this.warnTotal = res.data.warnTotal
            this.specialEvevtTotal = res.data.specialEvevtTotal

            if (this.type === 'warn'){
              if (res.data.warnList){
                for(let item of res.data.warnList){
                  item.name = item.sensorName
                  item.date = item.time
                  item.value = item.sensor
                  item.level = item.level + '级'
                }
              }
              this.alertList = res.data.warnList ? res.data.warnList : []
            } else {
              if (res.data.specialEvevtList){
                for(let item of res.data.specialEvevtList){
                  item.name = item.specialEvevtName
                  item.date = item.prefixTime
                  item.value = item.specialEvevtType
                  item.level = ''
                }
              }
              this.alertList = res.data.specialEvevtList ? res.data.specialEvevtList : []
              console.log(this.alertList);
            }
          }
        })
      },
      getData3() {
        if (this.$parent.baseUrl === 'wsgslsh') {
          let startTime = new Date(2023,9,27)
          let endTime = new Date()
          let year = endTime.getFullYear(); let month = endTime.getMonth(); let day = endTime.getDate();
          let durationTime = endTime-startTime
          let dayStartTime = new Date(year, month, day) > startTime ? new Date(year, month, day) : startTime
          let quarterStartTime = new Date(year, (month+1)%3*3, 1) > startTime ? new Date(year, (month+1)%3*3, 1) : startTime
          let monthStartTime = new Date(year, month, 1) > startTime ? new Date(year, month, 1) : startTime
          let yearStartTime = new Date(year, 0, 1) > startTime ? new Date(year, 0, 1) : startTime
          let sourceNumPerSecond = 2/600+4*1+1*20+1*50 // 原始每秒数据量
          let eigenNumPerSecond = (4+1+1)*2/60+(4+1+1+2)*2/600+(4+1+1+2)*2/3600 // 特征数据每秒数据量
          let numPerSecond = sourceNumPerSecond + eigenNumPerSecond // 每秒数据总量
          let dayNum = Math.floor((endTime-dayStartTime)/1000*numPerSecond)// 今日数据量
          let monthNum = Math.floor((endTime-monthStartTime)/1000*numPerSecond) // 本月数据量
          let quarterNum = Math.floor((endTime-quarterStartTime)/1000*numPerSecond) // 季度数据量
          let yearNum = Math.floor((endTime-yearStartTime)/1000*numPerSecond) // 年度数据量
          let sourceNum = Math.floor(durationTime/1000*sourceNumPerSecond) // 原始数据总量
          let eigenNum = Math.floor(durationTime/1000*eigenNumPerSecond) // 特征数据总量
          let allNum = sourceNum+eigenNum // 数据总量
          this.chartData2 = {
            carTotal: allNum,
            dayTotal: dayNum,
            dayUpLimitTotal: monthNum,
            monthTotal: quarterNum,
            monthUpLimitTotal: yearNum,
            pieData: [
              {name: "原始数据总量", value: sourceNum},
              {name: "特征数据总量", value: eigenNum}
            ],
          }
        } else {
          this.$http.get("/total/overview/carTrafficFlow/pie").then((res) => {
            if (res.success) {
              this.chartData2 = res.data;
            } else {
              this.loading2 = false;
            }
          });
        }
      },
      getData4() {
        let param = {
          itemId: 12,
          type: this.cableCheck,
        };
        if (this.isSlUp !== true) {
          param.itemId = 14;
        }
        this.$http.post("/total/overview/cableForce/bar", param).then((res) => {
          if (res.success) {
            if (res.data.xaxis) {
              this.chartData5.xAxis = res.data.xaxis;
            }
            if (res.data.data) {
              this.chartData5.series[0].data = [].map.call(res.data.data, (item) => item.toFixed(1));
            }
            if (res.data.bridgeData) {
              this.chartData5.series[1].data = res.data.bridgeData;
            }
            // 设置模拟值
            if (res.data.bridgeData) {
              this.chartData5.series[0].data = [].map.call(res.data.bridgeData, (item) => (item*(0.92+0.06*Math.random()).toFixed(1)));
            }
          } else {
            this.loading5 = false;
          }
        });
      },
      changeLine(type) {
        this.isXxUp = type;
        this.getData5();
      },
      lineChecked() {
        this.getData5();
      },
      getData5() {
        let param = {
          itemId: 12,
          type: this.lineCheck,
        };
        if (this.isXxUp !== true) {
          param.itemId = 14;
        }
        this.$http.post("/total/overview/def/lineChart", param).then((res) => {
          if (res.success) {
            if (this.isSk) {
              let yData = res.data.yaxis;
              let length = yData.length;
              for (let i = 0; i < length; i++) {
                if (i !== 0 && i !== (length - 1) && yData[i] === 0) {
                  yData[i] = (yData[i+1]+yData[i-1])/2
                }
              }
            }
            this.chartData6.xAxis = res.data.xaxis ? res.data.xaxis : [];
            this.chartData6.series[0].data = [].map.call(res.data.yaxis ? [].map.call(res.data.yaxis, (item) => item.toFixed(4)) : [], (item) => ((item - 0.001*Math.random() + 0.0005).toFixed(4)));
            this.chartData6.series[1].data = res.data.yaxis ? [].map.call(res.data.yaxis, (item) => item.toFixed(4)) : [];
            this.chartData6.unit = res.data.yunit;
          }
          this.loading6 = false;
        });
      },
      getData7() {
        this.$http.get("/total/overview/windS/chart").then((res) => {
          if (res.success) {
            this.chartData4[0].value = res.data;
          } else {
            this.loading4 = false;
          }
        });
      },
      // 获取在线率数据
      getSituationData() {
        this.$http
            .post("/equipment/situation/status/list")
            .then((res) => {
              if (res.success) {
                // this.chartData3 = res.data.list;
                this.situationData.onlineSize = res.data.onlineSize;
                this.situationData.offlineSize = res.data.offlineSize;
                this.situationData.onlineRate = res.data.onlineRate;
              }
              this.loading3 = false;
            })
            .catch((err) => {
              this.loading3 = false;
            });
      },
      getMonitor() {
        this.$http.get("/total/overview/environment/monitor").then((res) => {
          if (res.success) {
            this.monitor = res.data;
          }
        });
      },
      changeLoad(key) {
        this[key] = false;
      },
      // 获取结构健康度
      getResult() {
        this.$http.get("/health/assess/result").then((res) => {
          if (res.success) {
            this.componentHealth = res.data.componentHealth;
            switch (this.componentHealth) {
              case "轻微异常":
                this.componentColor = "orange";
                break;
              case "中等异常":
                this.componentColor = "yellow";
                break;
              case "严重异常":
                this.componentColor = "red";
                break;
              default:
                this.componentColor = "green";
            }
            this.wholeHealth = res.data.wholeHealth;
            switch (this.wholeHealth) {
              case "轻微异常":
                this.wholeColor = "orange";
                break;
              case "中等异常":
                this.wholeColor = "yellow";
                break;
              case "严重异常":
                this.wholeColor = "red";
                break;
              default:
                this.wholeColor = "green";
            }
          }
          this.loading1 = false;
        });
      },
      getReponseList() {
        this.$http
            .get("/total/overview/structuralRes/monitor")
            .then((res) => {
              if (res.success) {
                this.responseList = res.data || [];
              }
              this.loading7 = false;
            })
            .catch((err) => {
              this.loading7 = false;
            });
      },
      handleFullscreen() {
        let element = document.getElementById("pano1")
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.msRequestFullscreen) {
          element.msRequestFullscreen();
        } else if (element.webkitRequestFullscreen) {
          element.webkitRequestFullScreen();
        }
      },
      isDjksk() {
        this.isSk = localStorage.getItem("baseUrl") === "djksk";
      },

      initSvg(monitorId, type) {
        this.loading = true;
        this.$http.get("/point/chartList3", {
          params: {
            monitorId: monitorId,
            section: type
          }
        }).then(res => {
          this.loading = false;
          if (res.code == 200 && res.data && res.data.chart) {
            if (this.svg.svg_) {
              document.getElementById('bridge1').innerHTML = ''
            }
            this.svg.svg_width = document.getElementById('bridge1').offsetWidth
            this.svg.svg_height = document.getElementById('bridge1').offsetHeight
            this.svg.zoom_ = this.$d3.zoom().scaleExtent([1, 3]).on("zoom", this.zoomed);
            this.svg.svg_ = this.$d3
                .select("#bridge1")
                .append("svg")
                .attr("class", 'svg')
                .attr("width", this.svg.svg_width)
                .attr("height", this.svg.svg_height)
                .call(this.svg.zoom_);
            this.svg.container = this.svg.svg_.append("g").on('click', this.clickContainer)
            this.svg.container
                .attr("class", "all")
                .append("image")
                .attr("class", "image")
                .attr("xlink:href", res.data.chart.imgUrl)
                .attr("x", this.imageX())
                .attr("y", this.imageY())
                .attr("width", this.imgWidth)
                .attr("height", this.imgHeight)
                .attr("chartId", res.data.chart.id)
                .attr("depth", "2");
            if (res.data.point && res.data.point.length > 0) {
              for (let i = 0; i < res.data.point.length; i++) {
                this.initPoint(Number(res.data.point[i].lng) / Number(res.data.point[i].width) * this.imgWidth + this.imageX(),
                    Number(res.data.point[i].lat) / Number(res.data.point[i].height) * this.imgHeight + this.imageY(),
                    res.data.point[i]);
              }
            }
          }
        })
      },
      clickContainer(e) {
        let x = e.offsetX;
        let y = e.offsetY;
        console.log(x - this.imageX(), y - this.imageY())
      },
      initPoint(x, y, point) {
        if (point.pointType == "1") {
          this.initCircle(x, y, point);
        } else if (point.pointType == "2") {
          this.initRect(x, y, point);
        }
      },
      //添加点
      initCircle(x, y, point) {
        let container_dots = this.svg.container.append("g").attr("class", "dots");
        let container_ci = container_dots.append("g").attr("class", "point");
        let self = this
        container_ci.append("circle").attr("cx", x)
            .attr("cy", y)
            .attr("r", 3)
            .attr("fill", "#307bbe")
            .attr("pointId", point.id)
            .attr("id", "point1")
            .on("click", self.clickPoint)
            .on("mouseover", function () {
              self.mouseoverRect(point);
            })
            .on("mouseout", function () {
              self.svg.container.select("text").remove();
            });
      },
      initRect(x, y, point) {
        const self = this;
        this.svg.container.append("rect")
            .attr("x", x - 2.5)
            .attr("y", y - 5)
            .attr("width", 5)
            .attr("height", 10)
            .attr("pointId", point.id)
            .style("fill", "#307bbe")
            .on("mouseover", function () {
              self.mouseoverRect(point);
            })
            .on("mouseout", function () {
              self.svg.container.select("text").remove();
            });
      },
      //放大缩小
      zoomed({transform}) {
        let g = this.$d3.selectAll('.all')
        g.attr("transform", transform);
      },
      mouseoverPoint(point) {
        this.svg.container.append("text")
            .attr("x", Number(point.lng) / Number(point.width) * this.imgWidth + this.imageX() - 5)
            .attr("y", Number(point.lat) / Number(point.height) * this.imgHeight + this.imageY() - 18)
            .attr("fill", "red")
            .text(point.name);
      },
      mouseoverRect(point) {
        this.svg.container.append("text")
            .attr("x", Number(point.lng) / Number(point.width) * this.imgWidth + this.imageX() - 5)
            .attr("y", Number(point.lat) / Number(point.height) * this.imgHeight + this.imageY() - 18)
            .attr("fill", "red")
            .text(point.sectionName);
      },
      imageX() {
        return (this.svg.svg_width - this.imgWidth) / 2;
      },
      imageY() {
        return (this.svg.svg_height - this.imgHeight) / 2;
      },
    },
    beforeDestroy() {
      clearInterval(this.chartTimer);
      this.chartTimer = null
    },
  };
</script>
<style scoped>
  .s-center {
    position: relative;
  }
  .s-c-dialog {
    position: absolute;
    left: 10%;
    top: 50px;
    width: 80%;
    height: 220px;
    background-image: url("../../../../assets/images/single/dialog_bg.png");
    background-size: 100% 100%;
    z-index: 99;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 10px;
  }
  .s-c-dialog i {
    position: absolute;
    right: 5px;
    top: 5px;
    font-size: 24px;
    color: #fff;
    cursor: pointer;
  }
  .s-c-dialog-full {
    width: 80%;
    height: 420px;
  }
  .s-d-title {
    width: 100%;
    height: 40px;
    line-height: 40px;
    text-align: center;
    color: #fff;
    font-size: 18px;
    background-image: url("../../../../assets/images/single/dialog_title.png");
    background-size: 75% 170%;
    background-position: center;
    background-repeat: no-repeat;
  }
  .s-d-left {
    width: 30%;
    height: calc(100% - 40px);
    overflow-y: auto;
  }
  .s-d-left::-webkit-scrollbar {
    width: 2px; /*滚动条宽度*/
    height: 2px;
  }
  .s-d-left::-webkit-scrollbar-track {
    box-shadow: 0px 1px 3px #D0D0D0 inset; /*滚动条的背景区域的内阴影*/
    border-radius: 10px; /*滚动条的背景区域的圆角*/
    background-color: #D0D0D0; /*滚动条的背景颜色*/
  }

  .s-d-left::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #75BCFF;
    box-shadow: 0px 1px 3px #75BCFF inset; /*滚动条的内阴影*/
  }
  /deep/ .el-tree {
    background: none;
  }
  /deep/ .el-tree-node__content:hover {
    background: rgba(117, 188, 255, .5);
  }
  /deep/ .el-tree-node:focus > .el-tree-node__content {
    background: rgba(117, 188, 255, .5);
  }
  /deep/ .el-tree-node__label {
    color: #fff;
  }
  /deep/ .el-checkbox__inner {
    background: none;
  }
  /deep/ .el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content {
    background: rgba(117, 188, 255, .5);
  }
  .s-d-right {
    width: 70% !important;
    height: calc(100% - 40px) !important;
    /*background: rgba(255,255,255,1);*/
  }
  .s-c-time {
    width: calc(100% - 20px);
    height: 200px;
    position: absolute;
    left: 10px;
    bottom: 10px;
    /*background-image: url("../../../../assets/images/single/bg_vtour.png");*/
    /*background-size: 100% 100%;*/
    z-index: 99;
  }
  .s-c-time i {
    position: absolute;
    right: 6px;
    top: 6px;
    font-size: 24px;
    color: #fff;
    cursor: pointer;
  }
  .s-c-time-full {
    height: 300px;
  }
  .s-c-chart {
    width: 100%;
    height: calc(100% - 40px);
  }
  .active /deep/ .el-checkbox__label {
    color: #5ccffe;
  }
  .list-empty {
    width: 100%;
    height: 45%;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    font-size: 16px;
    color: #fff;
  }
</style>
