<template>
  <div ref="dom" class="charts chart-bar"></div>
</template>

<script>
import * as echarts from "echarts";
import { on, off } from "@/libs/tools";
export default {
  name: "ChartBar",
  props: {
    value: Object,
    text: Number,
    subtext: String,
    loading:String
  },
  data() {
    return {
      dom: null,
    };
  },
  watch: {
    value: {
      handler(val) {
        this.loadEcharts(val);
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {},
  methods: {
    resize() {
      this.dom.resize();
    },
    loadEcharts(val) {
      this.$nextTick(() => {
        let option = {
          legend: {
            show: false,
          },
          toolbox: {
            show: false,
          },
          title: [
            {
              text: val.carTotal,
              textStyle: {
                color: "#FFA951",
                fontSize: 20,
              },

              itemGap: 20,
              left: "center",
              top: "40%",
            },
            {
              text: "车辆总数",
              textStyle: {
                color: "#fff",
                fontSize: 14,
                fontWeight: "normal",
              },

              itemGap: 20,
              left: "center",
              top: "53%",
            },
          ],
          tooltip: {
            trigger: "item",
            backgroundColor: "rgba(47,37,198,0.75)",
            borderColor: "#2880FF",
            borderWidth: 1,
            textStyle:{
              color: "#fff",
            }
          },
          series: [
            {
              name: "车流量监测",
              type: "pie",
              radius: ["50%", "65%"],
              center: ["50%", "50%"],
              // roseType: "area",
              label: {
                show: false,
              },
              labelLine: { show: false },
              selectedOffset: 30,
              selectedMode: true,
              itemStyle: {
                borderRadius: 0,
              },
              data: val.pieData,
            },
             {
            name: "",
            type: "gauge",
            radius: "110%",
            center: ['50%', '50%'],
            startAngle: 0,
            endAngle: 360,
            splitNumber:12,
            z:3,
            hoverAnimation: true,
            axisTick: {
                show: false
            },
            splitLine: {
                length:8,
                lineStyle: {
                    width: 2,
                    color: "#06a9f6"
                }
            },
            axisLabel: {
                show: false
            },
            pointer: {
                show: false
            },
            axisLine: {
                lineStyle: {
                    opacity: 0
                }
            },
            detail: {
                show: false
            },
            data: [1]
        },
        {
            name: "",
            type: "gauge",
            radius: "110%",
            center: ['50%', '50%'],
            startAngle: 0,
            endAngle: 360,
            splitNumber:60,
            z:2,
            hoverAnimation: true,
            axisTick: {
                show: false
            },
            splitLine: {
                length: 6,
                lineStyle: {
                    width: 1,
                    color: "#069ae6"
                }
            },
            axisLabel: {
                show: false
            },
            pointer: {
                show: false
            },
            axisLine: {
                lineStyle: {
                    opacity: 0
                }
            },
            detail: {
                show: false
            },
            data: [1]
        },
          ],
        };
        if (!this.dom) {
          this.dom = echarts.init(this.$refs.dom);
        }
        this.dom.setOption(option);
        on(window, "resize", this.resize);
        this.$parent.changeLoad(this.loading)
      });
    },
  },

  beforeDestroy() {
    off(window, "resize", this.resize);
  },
};
</script>
