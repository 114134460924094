<template>
  <div class="screen-pannel screen-three flex flex-col justify-between">
    <div class="s-top flex justify-between">
      <div class="s-left flex flex-col justify-between">
        <div class="s-item-large">
          <div class="s-item-title">振动监测</div>
          <div class="s-large-content">
            <div class="chart-container relative"
                 v-loading="loading1"
                 element-loading-text="拼命加载中"
                 element-loading-spinner="el-icon-loading"
                 element-loading-background="rgba(5, 31, 86, 1)"
            >
              <div class="chart-title flex justify-between items-center">
                <div class="flex items-center">
                  <img
                    class="mr-1"
                    src="../../../../assets/images/single/screen03/icon_jiashudu.png"
                    alt=""
                  />
                  <span>加速度</span>
                </div>
                <!-- <div class="text-white">
                  均方根<span class="color-green ml-2 text-lg">10.35</span>mg
                </div> -->
              </div>
              <div class="num-value" style="top: 2px">
                均方根
                <span class="color-blue ml-2 text-lg" v-if="chartData1.rms || chartData1.rms === 0">{{parseFloat(chartData1.rms).toFixed(3)}}</span>
                <span class="color-orange ml-2 text-lg" v-else>--</span>
                {{chartData1.yunit}}
              </div>
              <chart-one
                  ref="chartOne"
                style="height: calc(100% - 32px)"
                :value="chartData1"
              ></chart-one>
            </div>
            <div class="chart-container relative"
                 v-loading="loading2"
                 element-loading-text="拼命加载中"
                 element-loading-spinner="el-icon-loading"
                 element-loading-background="rgba(5, 31, 86, 1)"
            >
              <div class="chart-title flex justify-between items-center">
                <div class="flex items-center">
                  <img
                    class="mr-1"
                    src="../../../../assets/images/single/screen03/icon_pingpu.png"
                    alt=""
                  />
                  <span>频谱</span>
                </div>
                <!-- <div>
                  监测基频<span class="color-blue ml-2 text-lg">0.215</span>Hz
                </div> -->
              </div>
              <div class="num-value" style="top: 2px">
                监测基频
                <span class="color-orange ml-2 text-lg" v-if="chartData2.f || chartData2.f === 0">{{chartData2.f}}</span>
                <span class="color-blue ml-2 text-lg" v-else>--</span>Hz
              </div>
              <chart-two
                  ref="chartTwo"
                style="height: calc(100% - 32px)"
                :value="chartData2"
              ></chart-two>
            </div>
          </div>
        </div>
      </div>
      <div class="s-center">
         <img src="../../../../assets/images/single/brigde.jpg" alt="" class="w-full h-full">
      </div>
      <div class="s-right flex flex-col justify-between">
        <div class="s-item-small">
          <div class="s-item-title">涡振报警</div>
          <div class="chart-container flex items-center justify-center">
            <img
              src="../../../../assets/images/single/screen03/jpg_normal.png"
              alt=""
              style="height: 70%"
            />
          </div>
        </div>
        <div class="s-item-middle"
             v-loading="loading3"
             element-loading-text="拼命加载中"
             element-loading-spinner="el-icon-loading"
             element-loading-background="rgba(5, 31, 86, 1)"
        >
          <div class="s-item-title">环境监测</div>
          <div class="chart-container">
            <div class="env-top flex justify-around">
              <div>
                <div class="env-shidu h-full flex items-center justify-center">
                  <chart-eight
                    style="width: 100%; height: 100%"
                    :value="parseFloat(monitor.humidity).toFixed(2)"
                  ></chart-eight>
                </div>
                <div class="env-info">
                  <p class="env-info-label">湿度</p>
                  <p class="env-info-value color-blue">
                    <span class="text-lg" v-if="monitor.humidity || monitor.humidity === 0">{{parseFloat(monitor.humidity).toFixed(2)}}</span>
                    <span class="text-lg" v-else>--</span>%
                  </p>
                </div>
              </div>
              <div>
                <div class="env-wendu h-full flex items-center justify-center">
                  <chart-nine
                    style="width: 100%; height: 100%"
                    :value="parseFloat(monitor.temperature).toFixed(2)"
                  ></chart-nine>
                </div>
                <div class="env-info">
                  <p class="env-info-label">温度</p>
                  <p class="env-info-value color-green">
                    <span class="text-lg" v-if="monitor.temperature || monitor.temperature === 0">{{parseFloat(monitor.temperature).toFixed(2)}}</span>
                    <span class="text-lg" v-else>--</span>℃
                  </p>
                </div>
              </div>
              <div >
                <div class="env-fengsu h-full flex items-center justify-center">
                  <img
                  class="fengsu"
                    src="../../../../assets/images/single/screen03/icon_fengsan.png"
                    alt=""
                  />
                </div>
                <div class="env-info">
                  <p class="env-info-label">风速</p>
                  <p class="env-info-value color-blue">
                    <span class="text-lg" v-if="monitor.windS || monitor.windS === 0">{{parseFloat(monitor.windS).toFixed(2)}}</span>
                    <span class="text-lg" v-else>--</span>m/s
                  </p>
                </div>
              </div>
              <div >
                <div
                  class="env-fegnxiang h-full flex items-center justify-center"
                >
                  <img
                  style="transform: rotate(74deg);"
                    src="../../../../assets/images/single/screen03/icon_fengxiang.png"
                    alt=""
                  />
                </div>
                <div class="env-info">
                  <p class="env-info-label">风向</p>
                  <p class="env-info-value color-orange">
                    <span class="text-lg" v-if="monitor.windD || monitor.windD === 0">{{parseFloat(monitor.windD).toFixed(2)}}°</span>
                    <span class="text-lg" v-else>--°</span>
                  </p>
                </div>
              </div>
            </div>
            <div class="env-bottom  relative">
              <div class="env-legend flex items-center">
                <div class="legend-item">
                  <span class="line line-max"></span><span class="legend-label">风速上界</span>
                </div>
                <div class="legend-item">
                  <span class="line line-normal"></span><span class="legend-label">风速风向</span>
                </div>
                <div class="legend-item">
                  <span class="line line-min"></span><span class="legend-label">风速下界</span>
                </div>
              </div>
              <chart-seven
                style="height: 100%"
                :value="chartData6"
              ></chart-seven>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="s-bottom w-full flex justify-between">
      <div class="s-bottom-item"
           v-loading="loading4"
           element-loading-text="拼命加载中"
           element-loading-spinner="el-icon-loading"
           element-loading-background="rgba(5, 31, 86, 1)"
      >
        <div class="s-item-title">位移监测</div>
        <div class="chart-container relative">
          <div class="num-value">
            位移
            <span class="color-orange ml-2 text-lg" v-if="chartData3.yaxis">{{Math.max.apply(null,chartData3.yaxis)}}</span>
            <span class="color-blue ml-2 text-lg" v-else>--</span>
            {{chartData3.yunit}}
          </div>
          <chart-three ref="chartThree" style="height: 100%" :value="chartData3"></chart-three>
        </div>
      </div>
      <div class="s-bottom-item"
           v-loading="loading6"
           element-loading-text="拼命加载中"
           element-loading-spinner="el-icon-loading"
           element-loading-background="rgba(5, 31, 86, 1)">
        <div class="s-item-title flex justify-between items-center">风偏角</div>
        <div class="chart-container relative">
          <div class="num-value color-blue fpj">{{parseFloat(chartData4.windAngle).toFixed(2)}}°</div>
          <chart-four style="height: 100%" :value="chartData4" loading="loading6"></chart-four>
        </div>
      </div>
      <div class="s-bottom-item" v-loading="loading7"
           element-loading-text="拼命加载中"
           element-loading-spinner="el-icon-loading"
           element-loading-background="rgba(5, 31, 86, 1)">
        <div class="s-item-title flex justify-between items-center">风攻角</div>
        <div class="chart-container relative">
          <div class="num-value color-blue fgj">{{chartData5.data[0].value}}°</div>
          <chart-five style="height: 100%" :value="chartData5" loading="loading7"></chart-five>
        </div>
      </div>
      <div class="s-bottom-item"
           v-loading="loading5"
           element-loading-text="拼命加载中"
           element-loading-spinner="el-icon-loading"
           element-loading-background="rgba(5, 31, 86, 1)"
      >
        <div class="s-item-title">紊流度监测</div>
        <div class="chart-container relative">
          <div class="num-value">
            紊流度
            <span class="color-orange ml-2 text-lg" v-if="chartData7.turbulenceDegree || chartData7.turbulenceDegree === 0">{{parseFloat(chartData7.turbulenceDegree).toFixed(3)}}</span>
            <span class="color-orange ml-2 text-lg" v-else>--</span>
          </div>
          <chart-six style="height: 100%" :value="chartData7"></chart-six>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChartOne from "./charts/chart01.vue";
import ChartTwo from "./charts/chart02.vue";
import ChartThree from "./charts/chart03.vue";
import ChartFour from "./charts/chart04.vue";
import ChartFive from "./charts/chart05.vue";
import ChartSix from "./charts/chart06.vue";
import ChartSeven from "./charts/chart07.vue";
import ChartEight from "./charts/chart08.vue";
import ChartNine from "./charts/chart09.vue";

export default {
  components: {
    ChartOne,
    ChartTwo,
    ChartThree,
    ChartFour,
    ChartFive,
    ChartSix,
    ChartSeven,
    ChartEight,
    ChartNine,
  },
  data() {
    return {
      loading1:true,
      loading2:true,
      loading3:true,
      loading4:true,
      loading5:true,
      loading6:true,
      loading7:true,
      isSx: true,
      isSlUp: true,
      isXxUp: true,
      chartData01: [
        {
          // name: "GDP比重",
          value: 80,
        },
      ],
      chartData02: [
        76, 31, 52, 37, 55, 112, 100, 60, 90, 70, 80, 85, 90, 78, 76, 80, 0, 0,
        0, 0, 0, 0, 0, 0,
      ],
      chartData03: [
        { value: 40, name: "0" },
        { value: 38, name: "1" },
        { value: 32, name: "2" },
        { value: 30, name: "3" },
        { value: 28, name: "4" },
        { value: 26, name: "5" },
        { value: 40, name: "6" },
        { value: 38, name: "7" },
        { value: 85, name: "8" },
        { value: 30, name: "9" },
        // { value: 28, name: "10" },
      ],
      chartData04: [
        { value: 40, name: "15:00" },
        { value: 38, name: "15:00" },
        { value: 32, name: "15:00" },
        { value: 30, name: "15:00" },
        { value: 28, name: "15:00" },
        { value: 26, name: "15:00" },
      ],
      chartData05: [{ value: 90 }],
      chartData08: [{ value: 30 }],
      chartData06: [
        {
          name: "可可慕斯",
          value: [15, 12, 14, 17],
        },
        {
          name: "马卡龙",
          value: [13, 14, 5, 18],
        },
        {
          name: "布朗尼",
          value: [12, 14, 15, 17],
        },
      ],
      chartData1:{
        name: "",
        rms: 0,
        xaxis: [],
        yaxis: [],
        yunit: "mg",
      },
      chartData2:{
        data:[],
        xaxis:[],
        f:0
      },
      chartData3:{
        xaxis:[],
        yaxis:[],
        yunit:''
      },
      chartData4:{
        bridgeAngle:270,
        data:[{value:60}],
        windAngle:0
      },
      chartData5:{
        data:[{value:60}]
      },
      chartData6:{
        xAxis:[],
        markLine:[{data:0},{data:0}],
        series:[{
          name:'风速风向',
          data:[]
        }]
      },
      chartData7:{
        name:'',
        turbulenceDegree:0,
        xaxis:[],
        yaxis:[]
      },
      monitor:{
        humidity: 0,
        temperature: 0,
        windD: 0,
        windS: 0
      },
      timer:null,
      timer1:null
    };
  },
  computed: {},
  created() {
    this.getMonitor()
    this.getData1()
    this.getData2()
    this.getData3()
    this.getData4()
    this.getData5()
    this.getData6()
    this.getData7()
    // this.getWindAngle()
  },
  mounted() {
    let self = this
    this.timer = setInterval(function () {
      self.getData1()
    },1000)
    this.timer1 = setInterval(function () {
      self.getData2()
    },1000)
  },
  methods:{
    getWindAngle(){
      let bridgeAngle = this.chartData4.bridgeAngle
      let value = this.chartData4.data[0].value
      if (bridgeAngle > value || bridgeAngle === value){
        this.chartData4.windAngle =  Math.abs(90 - (bridgeAngle - value))
        if (bridgeAngle - value > 180){
          this.chartData4.windAngle =  Math.abs(90 - (bridgeAngle - value - 180))
        }
      } else {
        this.chartData4.windAngle =  Math.abs(90 - (value - bridgeAngle))
        if (value - bridgeAngle > 180){
          this.chartData4.windAngle =  Math.abs(90 - (value - bridgeAngle - 180))
        }
      }
    },
    getMonitor(){
      this.$http.get('/total/overview/environment/monitor').then(res => {
        if (res.success){
          this.monitor = res.data
        }
      })
    },
    getData1(){
      this.$http.get('/vortex/overview/vib/acceleration/rms/line').then(res => {
        if (res.success){
          this.chartData1 = res.data
          this.$refs.chartOne.refreshData(this.chartData1)
        } else {
          this.loading1 = false
        }
      })
    },
    getData2(){
      this.$http.get('/vortex/overview/vibration/line').then(res => {
        if (res.success){
          this.chartData2 = res.data
          this.$refs.chartTwo.refreshData(this.chartData2)
        } else {
          this.loading2 = false
        }
      })
    },
    getData3(){
      this.$http.get('/vortex/overview/displacement/line').then(res => {
        if (res.success){
          this.chartData3 = res.data
          this.$refs.chartThree.refreshData(this.chartData3)
        } else {
          this.loading4 = false
        }
      })
    },
    getData4(){
      this.$http.get('/vortex/overview/wind/deflection/angle/gauge').then(res => {
        if (res.success){
          this.chartData4.data[0].value = res.data
          // this.$refs.chartThree.refreshData(this.chartData3)
          this.getWindAngle()
        } else {
          this.loading6 = false
        }
      })
    },
    getData5(){
      this.$http.get('/vortex/overview/wind/attack/angle/gauge').then(res => {
        if (res.success){
          this.chartData5.data[0].value = res.data
          // this.$refs.chartThree.refreshData(this.chartData3)
        } else {
          this.loading7 = false
        }
      })
    },
    getData6(){
      this.$http.get('/vortex/overview/environmental/monitor/line').then(res => {
        if (res.success){
          this.chartData6.markLine = res.data.markLineList
          this.chartData6.series = res.data.series
          this.chartData6.xAxis = res.data.xdata
        } else {
          this.loading3 = false
        }
      })
    },
    getData7(){
      this.$http.get('/vortex/overview/turbulence/degree/line').then(res => {
        if (res.success){
          this.chartData7 = res.data
        } else {
          this.loading5 = false
        }
      })
    },
    changeLoad(key){
      this[key] = false
    }
  },
  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null
    clearInterval(this.timer1);
    this.timer1 = null
  }
};
</script>
