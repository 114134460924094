<template>
  <div ref="dom" class="charts chart-bar"></div>
</template>

<script>
import * as echarts from "echarts";
import { on, off } from "@/libs/tools";
export default {
  name: "ChartBar",
  props: {
    value: Object,
    text: String,
    subtext: String,
  },
  data() {
    return {
      datas: [],
      dom: null,
      now: new Date(),
      oneDay: 1000,
    };
  },
  mounted() {
    this.loadEcharts(this.value);
  },
  methods: {
    resize() {
      this.dom.resize();
    },
    loadEcharts(val) {
      let self = this;
      this.$nextTick(() => {
        // let xAxisData = self.datas.map((item) => item.name);
        // let seriesData = self.datas.map((item) => item.value);
        // let datas = [];
        // let s  = true
        // if (val.yaxis.length > 0){
        //   s = false
        // }
        let option = {
          tooltip: {
            trigger: "axis",
          },
          legend: {
            show: false,
          },
          grid: {
            top: "15%",
            left: 60,
            right: "10%",
            bottom: "15%",
            // containLabel: true
          },
          // graphic: {
          //   type: 'text',
          //   left: 'center',
          //   top: 'middle',
          //   silent: true,
          //   invisible: s,
          //   style: {
          //     fill: 'white',
          //     // fontWeight: 'bold',
          //     text: '暂无数据',
          //     fontSize: '20px'
          //   }
          // },
          xAxis: [
            {
              type: "category",
              axisLine: {
                //坐标轴轴线相关设置。数学上的x轴
                show: false,
                lineStyle: {
                  color: "#233653",
                },
              },
              axisLabel: {
                //坐标轴刻度标签的相关设置
                color: "#A4C9F97F",
                fontSize: 10,
                formatter: function (params) {
                  return params
                },
              },
              splitLine: {
                show: false,
                lineStyle: {
                  type: "dashed",
                  color: "#4CC6FF",
                },
              },
              axisTick: {
                show: false,
              },
              data: val.xaxis,
            },
          ],
          yAxis: [
            {
              name: "",
              nameTextStyle: {
                color: "#3CB6FC",
                fontSize: 12,
                padding: 10,
                align: "right",
              },
              // min: 0,
              splitLine: {
                show: true,
                lineStyle: {
                  type: "dashed",
                  color: "rgba(76,198,255,0.25)",
                },
              },
              axisLine: {
                show: false,
                lineStyle: {
                  color: "#233653",
                },
              },
              axisLabel: {
                show: true,
                color: "#DEF8FF7F",
                padding: 10,
                formatter: function (value) {
                  if (value === 0) {
                    return value;
                  }
                  return value;
                },
              },
              axisTick: {
                show: false,
              },
            },
          ],
          series: [
            {
              name: "加速度",
              type: "line",
              showSymbol: false,
              //   data: seriesData,
              data: val.yaxis,
              lineStyle: {
                //设置线条默认样式
                width: 1,
                color: "#2DE3A9",

                //设置线条hover样式
                // emphasis: { width: 1 },
              },
              // smooth: true
            },
          ],
        };
        if (this.dom != null && this.dom != "" && this.dom != undefined) {
          this.dom.dispose();
        }
        this.dom = echarts.init(this.$refs.dom);
        // this.dom.showLoading({
        //   text: 'loading',
        //   color: '#c23531',
        //   textColor: '#000',
        //   maskColor: 'rgba(255, 255, 255, 0.2)',
        //   zlevel: 0,
        // })
        // let self = this
        // setTimeout(() => {
        //   // setOption前隐藏loading事件
        //   self.dom.hideLoading();
        //   self.dom.setOption(option);
        // }, 1000);
        this.dom.setOption(option);
        on(window, "resize", this.resize);
        this.$parent.changeLoad('loading1')
      });
    },
    refreshData(data){
      this.dom.setOption({
        xAxis: [
          {
            data: data.xaxis,
          }
        ],
        series: [
          {
            data: data.yaxis,
          },
        ],
      });
    }
    // randomData() {
    //   let self = this;
    //   self.now = new Date(+self.now + self.oneDay);
    //   return {
    //     name: self.now.toString(),
    //     value: [self.now, Math.random() * 10],
    //   };
    // },
  },

  beforeDestroy() {
    off(window, "resize", this.resize);
  },
};
</script>
