<template>
  <div class="screen-pannel screen-jiaotong flex flex-col justify-between">
    <div class="s-top flex justify-between">
      <div class="s-left s-top-left">
        <div class="s-new-title">
          <span>视频监控</span>
        </div>
        <div class="sj-content">
          <div class="spjk-item">
            <div class="video-list">
              <el-collapse v-model="activeVideo1" accordion>
                <el-collapse-item :title="currentVideo1.name" name="video1">
                  <div
                    v-for="(v, j) in videoList1[0].children"
                    :key="j"
                    class="video-list-item ellipsis"
                    :class="{ active: v.id == currentVideo1.id }"
                    @click="handleShowVideo(v, 1)"
                  >
                    {{ v.name }}
                  </div>
                </el-collapse-item>
              </el-collapse>
            </div>
            <vue-aliplayer-v2
              ref="videoPlayer1"
              :source="currentVideo1.url"
              :options="options"
            />
            <img
              title="全屏"
              class="icon-fullscreen"
              src="../../../../assets/images/single/jiaotong/icon_fullscreen.png"
              alt=""
              @click="handleFullScreen('videoPlayer1')"
            />
          </div>
          <div class="spjk-item">
            <div class="video-list">
              <el-collapse v-model="activeVideo2" accordion>
                <el-collapse-item :title="currentVideo2.name" name="video2">
                  <div
                    v-for="(v, j) in videoList2[0].children"
                    :key="j"
                    class="video-list-item ellipsis"
                    :class="{ active: v.id == currentVideo2.id }"
                    @click="handleShowVideo(v, 2)"
                  >
                    {{ v.name }}
                  </div>
                </el-collapse-item>
              </el-collapse>
            </div>
            <vue-aliplayer-v2
              ref="videoPlayer2"
              :source="currentVideo2.url"
              :options="options"
            />
            <img
              title="全屏"
              class="icon-fullscreen"
              src="../../../../assets/images/single/jiaotong/icon_fullscreen.png"
              alt=""
              @click="handleFullScreen('videoPlayer2')"
            />
          </div>
        </div>
      </div>
      <div class="s-right s-top-right">
        <div class="s-new-title">
          <span>超限检测</span>
        </div>
        <div
          class="sj-content"
          v-loading="loading1"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(5, 31, 86, 1)"
        >
          <div class="cx-top">
            <div
              class="cx-top-item"
              v-for="(item, index) in chartData1.pie"
              :key="index"
            >
              <div class="num">{{ item.value }}</div>
              <div class="name">{{ item.name }}</div>
            </div>
          </div>
          <div class="cx-middle flex justify-around">
            <div
              class="cx-middle-item"
              v-for="(item, i) in chartData1.carOverrun"
              :key="i"
            >
              <div class="name">{{ item.name }}</div>
              <div class="num">{{ item.value }}</div>
            </div>
          </div>
          <vue-seamless-scroll
            v-if="chartData1.carOverrunList.length !== 0"
            :data="chartData1.carOverrunList"
            class="seamless-warp"
            :class-option="classOption"
          >
            <div class="alert-list">
              <el-row
                class="a-list-item"
                v-for="(item, index) in chartData1.carOverrunList"
                :key="index"
              >
                <el-col :span="6" class="ellipsis" :title="item.laneNum">{{item.laneNum }}</el-col>
                <el-col :span="5" class="ellipsis" :title="item.carNum">{{item.carNum }}</el-col>
                <el-col :span="5" class="ellipsis" :title="item.grossWeight">{{item.grossWeight }}</el-col>
                <el-col :span="3" class="ellipsis" :title="item.speed">{{item.speed }}</el-col>
                <el-col :span="5" class="ellipsis" :title="item.strTime">{{item.strTime }}</el-col>
              </el-row>
            </div>
          </vue-seamless-scroll>
          <div class="list-empty" v-else>暂无数据</div>
        </div>
      </div>
    </div>
    <div class="s-bottom w-full flex justify-between">
      <div class="s-left s-bottom-left">
        <div class="s-new-title">
          <span>当日左/右幅平均车速</span>
        </div>
        <div
          class="sj-content"
          v-loading="loading2"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(5, 31, 86, 1)"
        >
          <chart-six
            style="height: 100%"
            :value="chartData2"
            loading="loading2"
          ></chart-six>
        </div>
      </div>
      <div class="s-right s-bottom-right">
        <div class="s-new-title">
          <span>车流量监控</span>
        </div>
        <div
          class="sj-content"
          v-loading="loading3"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(5, 31, 86, 1)"
        >
          <div class="clljk-top">
            <div class="clljk-top-item">
              <div class="icon icon-1"></div>
              <div class="info">
                <div class="name">今日车流量</div>
                <div class="num">{{ chartData3.dayCarTrafficFlow }}</div>
              </div>
            </div>
            <div class="clljk-top-item">
              <div class="icon icon-1"></div>
              <div class="info">
                <div class="name">本月车流量</div>
                <div class="num">{{ chartData3.monthCarTrafficFlow }}</div>
              </div>
            </div>
          </div>
          <chart-seven
            style="height: calc(100% - 90px)"
            loading="loading3"
            :value="chartData3"
          ></chart-seven>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueAliplayerV2 from "vue-aliplayer-v2";
import vueSeamlessScroll from "vue-seamless-scroll";
import ChartSix from "./charts/chart06.vue";
import chartSeven from "./charts/chart07.vue";

export default {
  components: {
    VueAliplayerV2,
    vueSeamlessScroll,
    ChartSix,
    chartSeven,
  },
  data() {
    return {
      loading1: true,
      loading2: true,
      loading3: true,
      chartData1: {
        carOverrun: [
          { name: "今日超限车辆", value: 0 },
          { name: "本月超限车辆", value: 0 },
        ],
        carOverrunList: [],
        pie: [
          // { name: "上行", value: 0 },
          // { name: "下行", value: 0 },
          { name: "左幅", value: 0 },
          { name: "右幅", value: 0 },
        ],
      },
      skPie: [
        { name: "左幅", value: 0 },
        { name: "右幅", value: 0 },
      ],
      chartData2: {
        xAxis: [],
        series: [],
      },
      chartData3: {
        dayCarTrafficFlow: 0,
        monthCarTrafficFlow: 0,
        series: [{data:[]},{data:[]}],
        yaxis: [],
      },
      centerTitle: "左幅监控",
      mainData: {
        carTrafficFlowTotal: 0,
        overloadCarTotal: 0,
      },
      options: {
        height: "100%",
        isLive: true,
        format: "m3u8",
        autoplay: true,
        skinLayout: false,
      },
      currentVideo1: {
        id: "1-1",
        name: "左幅",
        url: "",
      },
      currentVideo2: {
        id: "2-1",
        name: "右幅",
        url: "",
      },
      videoList1: [
        {
          id: 1,
          name: "",
          children: [],
        },
      ],
      videoList2: [
        {
          id: 2,
          name: "",
          children: [],
        },
      ],
      activeVideo1: "",
      activeVideo2: "",
      chartTimer:null,
      isSk: localStorage.getItem("baseUrl") === "djksk"
    };
  },
  computed: {
    classOption() {
      return {
        step: 0.1, // 数值越大速度滚动越快
        limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
  },
  created() {
    let bridgePrefix = localStorage.getItem("baseUrl");
    let videoList = this.$BRIDGE_VIDEO_LIST[bridgePrefix];
    if (videoList) {
      this.videoList1[0].children = videoList.upStream;
      this.videoList2[0].children = videoList.downStream;
      this.currentVideo1 = videoList.upStream[0];
      this.currentVideo2 = videoList.downStream[0];
    }
    this.getMainData(0);
    this.getData4("loading2");
    this.getData5();
    this.getData6();
    this.getVideoData();
    let self = this;
    self.chartTimer = setInterval(function () {
      self.getData4("loading2");
      self.getData5();
      self.getData6();
    },60000)
  },
  mounted() {},
  methods: {
    handleShowVideo(data, type) {
      this["activeVideo" + type] = "";
      this["currentVideo" + type] = data;
    },
    handleFullScreen(type) {
      this.$refs[type].requestFullScreen();
    },
    handleVideoChange() {
      let title = this.centerTitle == "左幅监控" ? "右幅监控" : "左幅监控";
      this.centerTitle = title;
      if (this.centerTitle === "左幅监控") {
        this.getMainData(0);
      } else {
        this.getMainData(1);
      }
    },
    // 获取监控地址
    getVideoData() {
      this.$http.get("/video/channel/list").then((res) => {
        if (res.success) {
          console.log("监控信息", res.data);
        }
      });
    },
    getMainData(type) {
      this.$http
        .get("/vehicle/overview/carOverweightAndFlow", {
          params: { type: type },
        })
        .then((res) => {
          if (res.success) {
            this.mainData = res.data;
          }
        });
    },
    getData4(key) {
      this.$http.get("/total/overview/carUpDownSpeed").then((res) => {
        if (res.success) {
          this.chartData2.xAxis = res.data.xdata;
          this.chartData2.series = res.data.series;
        } else {
          this[key] = false;
        }
      });
    },
    getData5() {
      this.$http.get("/vehicle/overview/carOverrun/pie").then((res) => {
        if (res.success) {
          this.chartData1 = res.data;
          if(this.isSk) this.chartData1.pie = this.skPie;
        }
        this.loading1 = false;
      });
    },
    getData6() {
      this.$http.get("/vehicle/overview/carTrafficFlow/bar").then((res) => {
        if (res.success) {
          this.chartData3 = res.data;
        } else {
          this.loading3 = false;
        }
      });
    },
    changeLoad(key) {
      this[key] = false;
    }
  },
  beforeDestroy() {
    clearInterval(this.chartTimer);
    this.chartTimer = null
  }
};
</script>

