<template>
  <div ref="dom" class="charts chart-bar"></div>
</template>

<script>
import * as echarts from "echarts";
import { on, off } from "@/libs/tools";
export default {
  name: "ChartBar",
  props: {
    value: String,
    text: String,
    subtext: String,
  },
  data() {
    return {
      dom: null,
    };
  },
  watch: {
    value: {
      handler(val) {
        // console.log(val);
        this.loadEcharts(val);
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {},
  methods: {
    resize() {
      this.dom.resize();
    },
    loadEcharts(val) {
      val = parseFloat(val)
      this.$nextTick(() => {
        // let xAxisData = val.map((item) => item.name);
        // let seriesData = val.map((item) => item.value);
        let option = {
          title: {
            text: "",
            show: false,
          },
          grid: {
            top: "15%",
            left: "0",
            right: "0",
            bottom: "15%",
            // containLabel: true
          },
          yAxis: [
            {
              show: false,
              data: [],
              min: 0,
              max: 100,
              axisLine: {
                show: false,
              },
            },
            {
              show: false,
              //   min: 0,
              //   max: 50,
            },
            {
              type: "category",
              //   position: "left",
              //   offset: -80,
              axisLabel: {
                fontSize: 10,
                color: "white",
              },
              axisLine: {
                show: false,
              },
              axisTick: {
                show: false,
              },
            },
          ],
          xAxis: [
            {
              show: false,
              data: [],
            },
            {
              show: false,
              data: [],
            },
            {
              show: false,
              data: [],
            },
            {
              show: false,
            },
          ],
          series: [
            {
              name: "条",
              type: "bar",
              // 对应上面XAxis的第一个对)象配置
              xAxisIndex: 0,
              data: [{value:val}],
              barWidth: 6,
              itemStyle: {
                // normal: {
                borderRadius: 50,
                // color: new echarts.graphic.LinearGradient(0, 1, 0, 0, Gradient)
                color: (params) => {
                  return new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                    {
                      offset: 0,
                      color: "#8C0707",
                    },
                    {
                      offset: 1,
                      color: "#F44C41",
                    },
                  ]);
                },
                // },
              },
              z: 2,
            },
            {
              name: "外框",
              type: "bar",
              xAxisIndex: 2,
              barGap: "-100%",
              data: [100],
              barWidth: 6,
              itemStyle: {
                color: "#4577BA",
                borderRadius: 50,
              },
              z: 0,
            },
            {
              name: "圆",
              type: "scatter",
              hoverAnimation: false,
              data: [0],
              xAxisIndex: 0,
              symbolSize: 10,
              itemStyle: {
                // color: "#8C0707",
                color: {
                  type: "radial",
                  x: 0.5,
                  y: 0.5,
                  r: 0.5,
                  colorStops: [
                    {
                      offset: 0,
                      color: "#8C0707", // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "#F44C41", // 100% 处的颜色
                    },
                  ],
                  global: false, // 缺省为 false
                },
                opacity: 1,
              },
              z: 2,
            },
          ],
        };
        if (this.dom != null && this.dom != "" && this.dom != undefined) {
          this.dom.dispose();
        }
        this.dom = echarts.init(this.$refs.dom);
        this.dom.setOption(option);
        on(window, "resize", this.resize);
      });
    },
  },

  beforeDestroy() {
    off(window, "resize", this.resize);
  },
};
</script>
