<template>
  <div ref="dom" class="charts chart-bar"></div>
</template>

<script>
import * as echarts from "echarts";
import { on, off } from "@/libs/tools";
export default {
  name: "ChartPie",
  props: {
    value: Object,
    loading: String,
  },
  data() {
    return {
      dom: null,
    };
  },
  watch: {
    value: {
      handler(val) {
        this.loadEcharts(val);
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    // this.loadEcharts();
  },
  methods: {
    resize() {
      this.dom.resize();
    },
    loadEcharts(val) {
      this.$nextTick(() => {
        let option = {
          title: {
            show: false,
          },
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
              color: "rgba(96, 199, 237, 0.15)",
            },
          },
          legend: {
            show: true,
            top: 0,
            right: 5,
            itemWidth: 15,
            itemHeight: 2,
            textStyle: {
              color: "rgba(255,255,255,.5)",
              fontSize: 12,
            },
          },
          grid: {
            left: 40,
            right: 20,
            bottom: 20,
            top: 20,
          },
          xAxis: {
            type: "category",
            splitLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLine: {
              show: true,
              lineStyle: {
                color: "rgba(104, 168, 204, 0.4)",
              },
            },
            axisLabel: {
              margin: 4,
              color: "#fff",
            },
            data: val.yaxis,
          },
          yAxis: [
            {
              type: "value",
              min: 0,
              axisLine: {
                show: false,
              },
              axisTick: {
                show: false,
              },
              splitLine: {
                show: true,
                lineStyle: {
                  type: "solid",
                  color: "#999999",
                  opacity: 0.23,
                },
              },
              axisLabel: {
                show: true,
                color: "#fff",
              },
            },
          ],
          series: [
            {
              // name: "上行",
              name: "左幅",
              type: "bar",
              // barWidth: 14,
              //   yAxisIndex: 0,
              itemStyle: {
                borderWidth: 1,
                borderColor: "#3681FF",
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "rgba(54, 129, 255, 0)" },
                  { offset: 1, color: "rgba(54, 129, 255, .8)" },
                ]),
              },
              data: val.series[0].data,
            },
            {
              // name: "下行",
              name: "右幅",
              type: "bar",
              // barWidth: 14,
              //   yAxisIndex: 1,
              itemStyle: {
                borderWidth: 1,
                borderColor: "#36EAFF",
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: "rgba(54, 234, 255, 0)" },
                  { offset: 1, color: "rgba(54, 234, 255, .8)" },
                ]),
              },
              data: val.series[1].data,
            },
          ],
        };
        // if (this.dom != null && this.dom != "" && this.dom != undefined) {
        //   this.dom.dispose();
        // }
        if (!this.dom) {
          this.dom = echarts.init(this.$refs.dom);
        }
        this.dom.setOption(option);
        on(window, "resize", this.resize);
        this.$parent.changeLoad(this.loading);
        // this.getTime(this.value);
      });
    },
    getTime(data) {
      let self = this;
      let arr = [
        "丹江口大桥",
        "开封峪大桥",
        "随岳汉江大桥",
        "清溪河大桥",
        "仙桃汉江大桥",
        "丹江口汉江大桥",
        "武汉长江大桥",
      ];
      this.timer = setInterval(function () {
        data.xAxis.push(arr[arr.length - 1]);
        arr.push(arr[0]);
        data.series[0].push(Math.random() * 10);
        data.series[1].push(Math.random() * 10);
        // data.series[2].push(Math.random()*10);
        data.xAxis.shift();
        arr.shift();
        data.series[0].shift();
        data.series[1].shift();
        // data.series[2].shift();
        self.dom.setOption({
          xAxis: {
            data: data.xAxis,
          },
          series: [
            {
              data: data.series[0],
            },
            {
              data: data.series[1],
            },
          ],
        });
      }, 1000);
    },
  },

  beforeDestroy() {
    off(window, "resize", this.resize);
  },
};
</script>
<style scoped>
.charts {
  width: 100%;
  height: 100%;
}
</style>
