<template>
  <div ref="dom" class="charts chart-bar"></div>
</template>

<script>
import * as echarts from "echarts";
import { on, off } from "@/libs/tools";
export default {
  name: "ChartBar",
  props: {
    value: Object,
    text: String,
    subtext: String,
  },
  data() {
    return {
      datas: [],
      dom: null,
      now: new Date(),
      oneDay: 1000,
    };
  },
  mounted() {
    this.loadEcharts(this.value);
  },
  methods: {
    resize() {
      this.dom.resize();
    },
    loadEcharts(val) {
      this.$nextTick(() => {
        let option = {
          tooltip: {
            trigger: "axis",
          },
          legend: {
            show: false,
          },
          grid: {
            top: "15%",
            left: 50,
            right: "10%",
            bottom: "15%",
            // containLabel: true
          },
          xAxis: [
            {
              type: "category",
              boundaryGap: true,
              axisLine: {
                //坐标轴轴线相关设置。数学上的x轴
                show: false,
                lineStyle: {
                  color: "#233653",
                },
              },
              axisLabel: {
                //坐标轴刻度标签的相关设置
                color: "#A4C9F97F",
                fontSize: 10,
              },
              splitLine: {
                show: false,
                lineStyle: {
                  type: "dashed",
                  color: "#4CC6FF",
                },
              },
              axisTick: {
                show: false,
              },
              data: val.xaxis,
            },
          ],
          yAxis: [
            {
              name: "",
              nameTextStyle: {
                color: "#3CB6FC",
                fontSize: 12,
                padding: 10,
                align: "right",
              },
              // min: 0,
              splitLine: {
                show: true,
                lineStyle: {
                  type: "dashed",
                  color: "rgba(76,198,255,0.25)",
                },
              },
              axisLine: {
                show: false,
                lineStyle: {
                  color: "#233653",
                },
              },
              axisLabel: {
                show: true,
                color: "#DEF8FF7F",
                padding: 10,
                formatter: function (value) {
                  if (value === 0) {
                    return value;
                  }
                  return value;
                },
              },
              axisTick: {
                show: false,
              },
            },
          ],
          // graphic: {
          //   type: 'text',
          //   left: 'center',
          //   top: 'middle',
          //   silent: true,
          //   invisible: true,
          //   style: {
          //     fill: 'white',
          //     // fontWeight: 'bold',
          //     text: '暂无数据',
          //     fontSize: '20px'
          //   }
          // },
          series: [
            {
              name: "频谱",
              type: "line",
              showSymbol: false,
              //   symbol:'circle',
              data: val.data,
              lineStyle: {
                //设置线条默认样式
                width: 2,
                color: "#00EFFE",
              },
              smooth: false,
              // markPoint: {
              //   symbol: "circle",
              //   symbolSize: 8,
              //   label: {
              //     show: false,
              //   },
              //   itemStyle: {
              //     color: "#00EFFE",
              //     borderColor:'rgba(0, 239,254, 0.5)',
              //     borderWidth:6,
              //     // shadowColor: "rgba(0, 239,254, 0.5)",
              //     // shadowBlur: 10,
              //   },
              //   data: [{ type: "max", name: "最高", value: "" }],
              // },
            },
          ],
        };
        if (this.dom != null && this.dom != "" && this.dom != undefined) {
          this.dom.dispose();
        }
        this.dom = echarts.init(this.$refs.dom);
        this.dom.setOption(option);
        on(window, "resize", this.resize);
        this.$parent.changeLoad('loading2')
      });
    },
    refreshData(data){
      this.dom.setOption({
        xAxis: [
          {
            data: data.xaxis,
          }
        ],
        series: [
          {
            data: data.data,
          },
        ],
      });
    }
  },

  beforeDestroy() {
    off(window, "resize", this.resize);
  },
};
</script>
