<template>
  <div ref="dom" class="charts chart-bar"></div>
</template>

<script>
import * as echarts from "echarts";
import { on, off } from "@/libs/tools";
export default {
  name: "ChartBar",
  props: {
    value: Object,
    text: String,
    subtext: String,
    loading: String,
  },
  data() {
    return {
      dom: null,
      option:{
        xAxis:[{data:[]}],
        series: [{ data: [] }, { data: [] }]
      }
    };
  },
  watch: {
    value: {
      handler(val) {
        this.loadEcharts(val);
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {},
  mounted() {
    this.loadEcharts(this.value)
  },
  methods: {
    resize() {
      this.dom.resize();
    },
    loadEcharts(val) {
      let self = this
      this.$nextTick(() => {
        self.option = {
          tooltip: {
            trigger: "axis",
          },
          legend: {
            show: true,
            top: "20%",
            right: "5%",
            itemWidth: 12,
            itemHeight: 3,
            textStyle: {
              color: "#fff",
              fontSize: 12,
            },
          },
          grid: {
            top: "30%",
            left: "10%",
            right: "5%",
            bottom: "15%",
            // containLabel: true
          },
          xAxis: [
            {
              type: "category",
              boundaryGap: true,
              axisLine: {
                //坐标轴轴线相关设置。数学上的x轴
                show: false,
                lineStyle: {
                  color: "#233653",
                },
              },
              axisLabel: {
                //坐标轴刻度标签的相关设置
                color: "#93DCFE",
                opacity: "0.5",
                padding: 4,
                fontSize: 12,
                formatter: function (data) {
                  return data;
                },
              },
              splitLine: {
                show: false,
                lineStyle: {
                  type: "dashed",
                  color: "#4CC6FF",
                },
              },
              axisTick: {
                show: false,
              },
              data: val.xAxis,
            },
          ],
          yAxis: [
            {
              name: val.unit,
              scale: true,
              nameTextStyle: {
                color: "#3CB6FC",
                fontSize: 12,
                padding: 10,
                align: "right",
              },
              splitLine: {
                show: true,
                lineStyle: {
                  type: "solid",
                  color: "rgba(142, 238, 255, .2)",
                },
              },
              axisLine: {
                show: false,
                lineStyle: {
                  color: "#233653",
                },
              },
              axisLabel: {
                show: true,

                color: "#93DCFE",
                padding: 10,

                formatter: function (value) {
                  if (value === 0) {
                    return value;
                  }
                  return value;
                },
              },
              axisTick: {
                show: false,
              },
            },
          ],
          series: [
            {
              name: "监测值",
              type: "line",
              // symbol: "circle", // 默认是空心圆（中间是白色的），改成实心圆
              showAllSymbol: true,
              symbolSize: 6,
              smooth: true,
              lineStyle: {
                width: 2,
                color: "#9999FF", // 线条颜色
                borderColor: "rgba(0,0,0,.4)",
              },
              tooltip: {
                show: true,
              },
              areaStyle: {
                //区域填充样式
                //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                color: new echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: "rgba(153, 153, 255, .4)",
                    },
                    {
                      offset: 1,
                      color: "rgba(153, 153, 255,0)",
                    },
                  ],
                  false
                ),
                shadowColor: "rgba(153, 153, 255, 0.5)", //阴影颜色
                shadowBlur: 20, //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
              },
              data: val.series[0].data,
            },{
              name: "成桥值",
              type: "line",
              // symbol: "circle", // 默认是空心圆（中间是白色的），改成实心圆
              showAllSymbol: true,
              symbolSize: 6,
              smooth: true,
              lineStyle: {
                width: 2,
                color: "#f2c000", // 线条颜色
                borderColor: "rgba(0,0,0,.4)",
              },
              tooltip: {
                show: true,
              },
              areaStyle: {
                //区域填充样式
                //线性渐变，前4个参数分别是x0,y0,x2,y2(范围0~1);相当于图形包围盒中的百分比。如果最后一个参数是‘true’，则该四个值是绝对像素位置。
                color: new echarts.graphic.LinearGradient(
                    0,
                    0,
                    0,
                    1,
                    [
                      {
                        offset: 0,
                        color: "rgba(153, 153, 255, .4)",
                      },
                      {
                        offset: 1,
                        color: "rgba(153, 153, 255,0)",
                      },
                    ],
                    false
                ),
                shadowColor: "rgba(153, 153, 255, 0.5)", //阴影颜色
                shadowBlur: 20, //shadowBlur设图形阴影的模糊大小。配合shadowColor,shadowOffsetX/Y, 设置图形的阴影效果。
              },
              data: val.series[1].data,
            },
          ],
        };
        if (!self.dom) {
          this.dom = echarts.init(this.$refs.dom);
        }
        this.dom.setOption(self.option);
        on(window, "resize", this.resize);
        this.$parent.changeLoad(this.loading);
      });
    }
  },

  beforeDestroy() {
    off(window, "resize", this.resize);
  },
};
</script>
